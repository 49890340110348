import React, {  useEffect, useState } from 'react';

import { integrationNames, StatusNames } from '@licoriceio/constants';
import { has } from '@licoriceio/utils';
import { Autocomplete, Chip, Divider, List, TextField } from "@mui/material";
import Popper from "@mui/material/Popper";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { GET, nameDataFormat, searchPanelChipType, uri } from "../../constants.js";
import { 
    setJobOffset, setFilterList, setJobCardCreatedTab, setSearchFilterString, setAddFilterItem, setRemoveFilterItem 
} from "../../redux/actions/index.js";
import { getTypeAheadControlState, setTypeAheadFilter, TypeAheadControl } from "../../redux/reducers/typeAhead.js";
import { getLoadingStates } from "../../redux/reducerUtil.js";
import { selectNameData } from "../../redux/selectors/index.js";
import {
    caretDown,
    chipCss,
    closeIcon,
    filterLine,
    filterLineChips,
    filterWidgetColumn,
    lastWidgetColumn,
    optionAvatar,
    optionAvatarCompany,
    optionRender,
    spacer,
    topJobLine,
    widget,
    widgetColumn,
    chipsRow,
    selectedFilterCss
} from '../../scss/SearchPanel.module.scss';
import { autocompleteOptions, closePanelWrap, displayCenter, hidePanel, roundInputBox, roundInputRoot, searchAutoComplete } from '../../scss/SearchTabContent.module.scss';
import { getAvatarFromUserOrCompany } from "../../utils/common-types.js";
import { __ } from '../../utils/i18n.jsx';
import { LicoAvatar, LicoFab, LicoIcon, LicoPopupMenuItem } from '../common';
import { getTeams } from "../settings/team/reducer.js";

import JobItem from "./JobItem.jsx";
import JobPager from './JobPager';
import JobStatusPopover from "./JobStatusPopover.jsx";
import { filterStringChanged, getJobsForTab, jobStatusMap, selectTabState, REOPENED_JOB_ICON, NOT_SCHEDULED_ICON } from "./reducer.js";
import popOverUseStyles from "./SearchPanelPopOverStyles";

const fixedOptionIcon = {
    [ searchPanelChipType.COMPANY ]:    <LicoIcon icon={'lightBuilding'} className={`${optionAvatarCompany} small`} />,
    [ searchPanelChipType.TEAM ]:       <LicoIcon icon={'users'} className={`${optionAvatarCompany} small`} />
};

const OptionIcon = ({ option }) => {
    return has( fixedOptionIcon, option.type )
        ? fixedOptionIcon[ option.type ]
        : <LicoAvatar
            avatar={option.avatar}
            gravatarEmail={option.preferences?.gravatarEmail}
            size="small"
            className={`${optionAvatar} small`}
        />;
};
OptionIcon.propTypes = {
    option:     PropTypes.object
};

const SearchTabContent = ({
    sortedJobIds,
    jobMap,
    filterString,
    filterStringChanged,
    jobOffset,
    numberVisibleJobs,
    setJobOffset,
    people,
    companies,
    teams,
    setTypeAheadFilter,
    getJobsForTab,
    id,
    filterList,
    setFilterList,
    jobStatusOptions,
    user,
    setSearchFilterString,
    getTeams,
    nameToId,
    total,
    setJobCardCreatedTab,
    setAddFilterItem,
    setRemoveFilterItem
}) => {
    const [ chips, setChips ] = useState([]);
    const [ anchorEl, setAnchorEl ] = useState( null );
    useEffect( () => {
        getTeams();
    }, [ getTeams ]);

    useEffect( () => {
        setChips( filterList.filter( item => !item.isFilterButton ).map( ({ label, type }) => ({ label, type }) ) );
        getJobsForTab( id );
    }, [ filterList, id, getJobsForTab ]);

    const isMenuOpen = Boolean( anchorEl );

    const resetElAnchor = () => {
        setAnchorEl( null );
    };
    const classes = popOverUseStyles();

    const handleDeleteChip = item => setRemoveFilterItem( item );

    const handleStatusClick = jobStatus => {
        setAddFilterItem({ ...jobStatus, type: searchPanelChipType.STATUS });
        resetElAnchor();
    };

    const handleIconClick = ( icon ) => {
        if ( isSelected( icon ) ) {
            const item = filterList.find( item => item.icon === icon );
            if ( item )
                handleDeleteChip( item );
        }
        else {
            if ( icon === 'hand' ) 
            {
                setAddFilterItem({ 
                    type:           searchPanelChipType.USER, 
                    icon, 
                    id:             user.userId, 
                    label:          __( 'Assigned to me' ), 
                    role:           user.role,  
                    isFilterButton: true  
                });
            }
            else 
            {
                const status = findStatus( icon );
                if ( status )
                {
                    const { id, label, slashed } = status;
                    setAddFilterItem({ 
                        type:           searchPanelChipType.STATUS,  
                        icon, 
                        label, 
                        id, 
                        slashed, 
                        isFilterButton: true 
                    });
                }

            }
        }
    };

    const findStatus =  icon  => {
        switch ( icon ) {
            case REOPENED_JOB_ICON: {
                return { id: nameToId[ StatusNames.REOPENED_JOB ], label: StatusNames.REOPENED_JOB };
            }
            case NOT_SCHEDULED_ICON:
                return { slashed: true, id: nameToId[ StatusNames.NOT_SCHEDULED ], label: StatusNames.NOT_SCHEDULED  };
        }
    };

    const onAutocompleteChange = ( e, newValue, reason ) => {
        if ( newValue === null ) 
            return;

        // we can get here in 2 ways:
        // * the user selects an option from the list (reason == selectOption)
        // * the user hits enter with a defined filter string (reason == createOption)
        // * the user hits backspace with an empty filter and a non-empty filterList (reason == removeOption). This removes the final 
        //   item in the list before we get here.
        // 
        // We want to turn string values into objects; the new value is always the last one in the list.
        if ( reason === 'createOption' )
        {
            const label = newValue.pop();
            newValue.push({ label, type: searchPanelChipType.STRING });
        }
        else if ( reason === 'selectOption' )
        {
            // there's no protection against selecting the same option twice, so pop it off and check it.
            const newOption = newValue.pop();
            if ( newValue.some( option => option.id === newOption.id && option.type === newOption.type ) )
                return;
            else
                newValue.push( newOption );
        }

        setFilterList({ selectedFilterList: newValue, id });
        setJobCardCreatedTab( false );
    };



    const customPopperStyle = {
        width: '300px'
    };

    const setJobOffsetForTab = ( offset ) => {
        setJobOffset( offset );
        getJobsForTab( id );
    };

    const processOptions = ( people, companies, teams ) => {
        const expandedArray = [];
        // Process the first array (people) and add avatar property
        if ( people?.length > 0 ) {
            const processedPeople = people.map( ( user ) => {
                return { 
                    id:             user.userId, 
                    role:           user.role,
                    label:          user.name || user.loginEmail,
                    preferences:    user.preferences,
                    avatar:         getAvatarFromUserOrCompany( user ), 
                    loginEmail:     user.loginEmail,
                    type:           searchPanelChipType.USER 
                };
            });
            expandedArray.push( ...processedPeople );
        }
        // Process the second array (companies) and add company property
        if ( companies?.length > 0 ) {
            const processedCompanies = companies.map( company => {
                return { id: company.id, label: company.label, type: searchPanelChipType.COMPANY };
            });
            expandedArray.push( ...processedCompanies );
        }
        if ( teams?.length > 0 ) {
            const processedTeams = teams.map( ( item ) => {
                return { ...item, id: item.tagId, type: searchPanelChipType.TEAM, label: item?.tagName };
            });
            expandedArray.push( ...processedTeams );
        }

        // ikm I don't know what this is supposed to do; the option list comes from mapStateToProps
        // and only has 3 entries. Removing it to see if anything breaks.
        // 
        // Push the rest of the array elements (starting from index 3)
        // expandedArray.push( ...arrays.slice( 3 ).flat() );
        return expandedArray;
    };

    let prevOptionType = null; // this is required to render the divider between different types of options

    const renderOptions = ( props, option ) => {
        const result = option.name || option.label;
        let renderedOption = (
            <div className={optionRender} {...props} key={option.id} >
                <OptionIcon option={option} />
                <div className={autocompleteOptions}>{result}</div>
            </div>
        );
        if ( prevOptionType !== null && option.type !== prevOptionType ) {
            renderedOption = (
                <div key={option.id}>
                    <Divider/>
                    {renderedOption}
                </div>
            );
        }
        prevOptionType = option.type; // Update the previous option's type
        return renderedOption;
    };

    const showDropdown = filterString?.length >= 3;
    const filteredJobStatusOptions = jobStatusOptions.filter(
        jobStatus => jobStatus.label !== StatusNames.NOT_SCHEDULED && jobStatus.label !== StatusNames.REOPENED_JOB
    );

    const onInputChange = ( event, value, reason ) => {
        if ( reason === 'reset' ) {
            setSearchFilterString( value );
            return;
        }
        filterStringChanged( value, id );
        filterString = value;
        setTypeAheadFilter({ name: TypeAheadControl.people, filter: value.length >= 1 ? value : '' });
        setTypeAheadFilter({ name: TypeAheadControl.searchPanelCompany, filter: value.length >= 3 ? value : '' });
    };

    const selectedIcons = filterList.filter( item => !!item.icon ).map( item => item.icon );

    const isSelected = ( icon ) => selectedIcons.includes( icon );

    const renderInput = ( params ) => (
        <TextField
            {...params}
            variant="outlined"
            placeholder="Filter"
            className={roundInputBox}
            InputProps={{
                ...params.InputProps,
                className: roundInputBox
            }}
        />
    );

    return (
        <div>
            <div className={topJobLine}>
                <span className={filterLine}>
                    <div className={filterLineChips}>
                        <div className={displayCenter}>
                            <LicoFab
                                color="inherit"
                                licoVariant="tiny"
                                onClick={() => getJobsForTab( id, numberVisibleJobs )}
                                className={`${closePanelWrap} grey-background`}
                            >
                                <LicoIcon icon="sync" className={hidePanel}/>
                            </LicoFab>
                        </div>
                        <div className={widget}>
                            <div className={filterWidgetColumn}>
                                <Autocomplete
                                    className={searchAutoComplete}
                                    freeSolo
                                    options={processOptions( people, companies, teams )}
                                    multiple
                                    disableClearable
                                    classes={{
                                        root:      roundInputRoot,
                                        inputRoot: roundInputRoot
                                    }}
                                    getOptionLabel={( option ) => option.name || option.label || option.loginEmail}
                                    filterSelectedOptions
                                    onChange={onAutocompleteChange}
                                    onInputChange={onInputChange}
                                    value={filterList}
                                    renderOption={( props, option ) => renderOptions( props, option )}
                                    renderInput={renderInput}
                                    renderTags={() => []}
                                    PopperComponent={( props ) => (
                                        showDropdown &&
                                        <Popper {...props} style={customPopperStyle} placement="bottom-start"/>
                                    )}
                                />

                            </div>

                            <div className={`${widgetColumn} ${isSelected( "hand" ) ? selectedFilterCss : ""}`} onClick={() => handleIconClick( "hand" )}>
                                <span>{__( "Me" )}</span>
                            </div>
                            <div className={`${widgetColumn} ${isSelected( REOPENED_JOB_ICON ) ? selectedFilterCss : ""}`} onClick={() => handleIconClick( REOPENED_JOB_ICON )}>
                                <LicoIcon icon={REOPENED_JOB_ICON} tooltip={StatusNames.REOPENED_JOB} tooltipPlacement={"top"} />
                            </div>
                            <div className={`${widgetColumn} ${isSelected( NOT_SCHEDULED_ICON )  ? selectedFilterCss : ""}`} onClick={() => handleIconClick( NOT_SCHEDULED_ICON )}>
                                <LicoIcon icon={NOT_SCHEDULED_ICON} slashed={true} tooltip={StatusNames.NOT_SCHEDULED} tooltipPlacement={"top"} />
                            </div>


                            <div className={lastWidgetColumn} onClick={e => setAnchorEl( e.currentTarget )}>
                                <LicoIcon icon="caretDown" className={caretDown}/>
                            </div>
                            <JobStatusPopover open={isMenuOpen} anchorEl={anchorEl} onClose={() => resetElAnchor()} classes={classes} jobStatus={jobStatusOptions}
                                map={filteredJobStatusOptions.map( jobStatus => (

                                    <LicoPopupMenuItem
                                        onClick={() => handleStatusClick( jobStatus )}
                                        icon= {( jobStatusMap[ jobStatus.label ].icon )}
                                        slashed = {jobStatusMap[ jobStatus.label ].slashed}
                                        key={jobStatus.id}
                                    >  {jobStatus.label} </LicoPopupMenuItem>

                                ) )}/>

                        </div>
                    </div>
                    <div className={chipsRow}>
                        {chips.map( ( chip, index ) => {
                            return (
                                <Chip
                                    key={index}
                                    label={chip.label}
                                    deleteIcon={<LicoIcon icon="close" className={closeIcon}/>}
                                    onDelete={() => handleDeleteChip( chip )}
                                    className={`RhsChip ${chipCss}`}
                                />
                            );
                        })}
                    </div>
                </span>
            </div>
            <div id="search-container">
                {(
                    <List>
                        {sortedJobIds
                            .map( jobId => (
                                <JobItem
                                    job={jobMap[ jobId ]}
                                    key={jobId}
                                    tabId={id}
                                />
                            ) )}
                    </List>
                )}
            </div>

            <div className={spacer}/>
            {total > numberVisibleJobs && (
                <JobPager
                    label={__( "Jobs" )}
                    start={jobOffset}
                    pageSize={numberVisibleJobs}
                    total={total}
                    setOffset={setJobOffsetForTab}
                />
            )}
        </div>
    );
};
SearchTabContent.propTypes = {
    sortedJobIds:           PropTypes.array,
    jobMap:                 PropTypes.object,
    filterString:           PropTypes.string.isRequired,
    filterStringChanged:    PropTypes.func.isRequired,
    setSearchFilterString:  PropTypes.func.isRequired,
    jobOffset:              PropTypes.number,
    numberVisibleJobs:      PropTypes.number,
    setJobOffset:           PropTypes.func,
    getJobsForTab:          PropTypes.func,
    people:                 PropTypes.array,
    companies:              PropTypes.array,
    teams:                  PropTypes.array,
    setTypeAheadFilter:     PropTypes.func.isRequired,
    id:                     PropTypes.string.isRequired,
    filterList:             PropTypes.array.isRequired,
    setFilterList:          PropTypes.func.isRequired,
    jobStatusOptions:       PropTypes.array.isRequired,
    user:                   PropTypes.object.isRequired,
    getTeams:               PropTypes.func.isRequired,
    nameToId:               PropTypes.object.isRequired,
    total:                  PropTypes.string,
    setJobCardCreatedTab:   PropTypes.func.isRequired,
    setAddFilterItem:       PropTypes.func.isRequired,
    setRemoveFilterItem:    PropTypes.func.isRequired
};

const mapStateToProps = ( state, ownProps ) => {
    const [ loadingJobs ] = getLoadingStates( state, GET + uri.TICKETS );
    const { data: people } = getTypeAheadControlState( state, TypeAheadControl.people );
    const { options: companies } = getTypeAheadControlState( state, TypeAheadControl.searchPanelCompany );
    const teams = state.team.teams;
    const {
        sortedJobIds,
        jobMap,
        filterString,
        jobOffset,
        numberVisibleJobs,
        filterList,
        total
    } = selectTabState( state, ownProps.id );
    return {
        sortedJobIds,
        jobMap,
        filterString,
        jobOffset,
        numberVisibleJobs,
        filterList,
        total,
        loadingJobs:            !!loadingJobs,
        people,
        companies,
        teams,
        jobStatusOptions:       selectNameData( state, integrationNames.JOB_STATUS, nameDataFormat.OPTIONS ),
        nameToId:               state.jobcard.jobStatus.nameToId,
        user:                   state.user
    };
};

const mapDispatchToProps = {
    setJobOffset,
    filterStringChanged,
    setTypeAheadFilter,
    getJobsForTab,
    setFilterList,
    setSearchFilterString,
    getTeams,
    setJobCardCreatedTab,
    setAddFilterItem,
    setRemoveFilterItem
};

export default connect( mapStateToProps, mapDispatchToProps )( SearchTabContent );
