/********************************************************************************************************************
 * @file Actions for the jobCard store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setDraftNoteText = createAction( 'JOB_CARD.DRAFT_NOTE_TEXT/SET' );
export const setJobNotes = createAction( 'JOB_CARD.NOTES/SET' );
export const setAddJobNote = createAction( 'JOB_CARD.ADD_NOTE/SET' );
export const setPendingNote = createAction( 'JOB_CARD.PENDING_NOTE/SET' );
export const setPendingDisplayNote = createAction( 'JOB_CARD.PENDING_DISPLAY_NOTE/SET' );
export const setUndoNoteTimeout = createAction( 'JOB_CARD.UNDO_NOTE_TIMEOUT/SET' );
export const clearPendingNote = createAction( 'JOB_CARD.CLEAR_PENDING_NOTE/SET' );
export const noteDataDeleted = createAction( 'JOB_CARD.NOTE_DATA_DELETED/SET' );
export const setTouchedJobCard = createAction( 'JOB_CARD.TOUCHED/SET' );
export const setErrorJobCard = createAction( 'JOB_CARD.ERROR/SET' );
export const setMessageText = createAction( 'JOB_CARD.MESSAGE_TEXT/SET' );
export const setNoteTextAction = createAction( 'JOB_CARD.NOTE_TEXT/SET' );
export const setCommentBillable = createAction( 'JOB_CARD.COMMENT_BILLABLE/SET' );
export const setBillingType = createAction( 'JOB_CARD.BILLING_TYPE/SET' );
export const setShowEditTimer = createAction( 'JOB_CARD.SHOW_EDIT_TIMER/SET' );
export const setEditableTime = createAction( 'JOB_CARD.EDITABLE_TIME/SET' );
export const setCurrentJobCardId = createAction( 'JOB_CARD.JOB_ID/SET' );
export const setJobCardDetails = createAction( 'JOB_CARD.JOB_CARD_DETAILS/SET' );
export const setJobCardPendingChange = createAction( 'JOB_CARD.PENDING_CHANGE' );
export const setJobCardAppointments = createAction( 'JOB_CARD.APPOINTMENTS/SET' );
export const setJobCardPeople = createAction( 'JOB_CARD.PEOPLE/SET' );
export const setJobCardSinglePerson = createAction( 'JOB_CARD.SINGLE_PERSON/SET' );
export const setJobCardUsers = createAction( 'JOB_CARD.USERS/SET' );
export const setJobCardEngineers = createAction( 'JOB_CARD.ENGINEERS/SET' );
export const patchJobCardUsers = createAction( 'JOB_CARD.PATCH_USERS/SET' );
export const patchJobCardEngineers = createAction( 'JOB_CARD.PATCH_ENGINEERS/SET' );
export const setJobCardChecklist = createAction( 'JOB_CARD.CHECKLIST/SET' );
export const setJobCardChecklistItemCreate = createAction( 'JOB_CARD.CHECKLIST_ITEM_CREATE/SET' );
export const setJobCardChecklistItemDelete = createAction( 'JOB_CARD.CHECKLIST_ITEM_DELETE/SET' );
export const setJobCardChecklistItemUpdate = createAction( 'JOB_CARD.CHECKLIST_ITEM_UPDATE/SET' );
export const setJobCardNewAppointment = createAction( 'JOB_CARD.NEW_APPOINTMENT/SET' );
export const setAppointmentHistory = createAction( 'JOB_CARD.APPOINTMENT_HISTORY/SET' );
export const setHideClientStatus = createAction( 'JOB_CARD.HIDE_CLIENT_STATUS/SET' );
export const clearContactChange = createAction( 'JOB_CARD.CONTACT_CHANGE/CLEAR' );
export const setNotesFullView = createAction( 'JOB_CARD.NOTES_FULL_VIEW/SET' );
export const setChatTabIndex = createAction( 'JOB_CARD.CHAT_TAB_INDEX/SET' );
export const setJobCardAssets = createAction( 'JOB_CARD.ASSET/SET' );
export const updateJobCardAssets = createAction( 'JOB_CARD.ASSET/UPDATE' );
export const deleteJobCardAsset = createAction( 'JOB_CARD.ASSET/DELETE' );
export const setSelectedAsset = createAction( 'JOB_CARD.ASSET/SET_SELECTED' );
export const setAssetFilterString = createAction( 'JOB_CARD.ASSET/SET_FILTER_STRING' );
export const setSelectedAssetMode = createAction( 'JOB_CARD.ASSET/SET_SELECTED_MODE' );
export const setSkipClientEmail = createAction( 'JOB_CARD.SKIP_CLIENT_EMAIL/SET' );
export const setPatchNoteList = createAction( 'JOB_CARD.PATCH_NOTE_LIST/SET' );
export const setBoardTypes = createAction( 'JOB_CARD.BOARD_TYPES/SET' );
export const setJobTag = createAction( 'JOB_CARD.JOB_TAG/SET' );
export const deleteJobTag = createAction( 'JOB_CARD.JOB_TAG/DELETE' );
