import { Priority } from '@licoriceio/constants';
import { adaptV4Theme, createTheme } from '@mui/material/styles';

export const PriorityRed = "#E64C66";
export const PriorityYellow = "#FFAB00";
export const PriorityBlue = "#00BFDD";
export const PriorityColorMap = {
    [ Priority.normal ]:        PriorityBlue,
    [ Priority.high ]:          PriorityYellow,
    [ Priority.critical ]:      PriorityRed
};

const themeOptions = {
    typography: {
        fontFamily:     "gotham-book, sans-serif",

        h1: {
            fontSize:   20,
            lineHeight: "24px"
        },

        h2: {
            fontSize:    14,
            fontFamily:  "gotham-medium, sans-serif",
            lineHeight:  "18px",
            fontWeight: 400
        },

        h3: {
            fontSize:   14,
            lineHeight: "18px",
            fontFamily: "gotham-medium, sans-serif"
        },
        h4: {
            fontSize:   12,
            lineHeight: "18px",
            fontFamily: "gotham-medium, sans-serif"
        },

        // P
        body1: {
            fontFamily: "baloo2-bold, sans-serif",
            color:      "var(--grey-2)",
            fontSize:   20,
            lineHeight: "24px"
        },

        body2: {
            fontFamily: "gotham-book, sans-serif",
            fontSize:   16,
            lineHeight: "28px"
        },

        caption: {
            fontFamily: "gotham-book, sans-serif",
            fontSize:   14,
            lineHeight: "21px"
        },

        button: {
            fontSize:      16,
            fontFamily:    "gotham-medium, sans-serif",
            textTransform: "none"
        }
    }
};

const themeOptionsWithOverides = {
    ...themeOptions,

    props: {
        // Disable ripple effect globally (originally designed for touch devices)
        MuiButtonBase: {
            disableRipple: true
        }
    },

    transitions: {
        // Disable transitions globally
        create: () => 'none'
    },

    overrides: {

        MuiPaper: {
            root: {
                backgroundColor: 'var(--white)'
            },
            rounded: {
                borderRadius: 4
            }
        },

        MuiAlert: {
            icon: {
                fontSize:       40,
                marginTop:      16,
                marginRight:    16
            },
            filledError: {
                backgroundColor:     'var(--watermelon)'
            }
        },

        MuiTypography: {
            root: {
                color:        "var(--grey-1)",
                marginBottom: "0"
            },

            h1: {
                "&.personality": {
                    fontSize:   60,
                    fontFamily: "baloo2-bold, sans-serif",
                    lineHeight: 60
                }
            },

            h2: {
                "&.personality": {
                    fontSize:   20,
                    fontFamily: "baloo2-bold, sans-serif",
                    lineHeight: 24
                }
            },

            gutterBottom: {
                marginTop:    4,
                marginBottom: '0.35em'
            },

            body1: {
                fontFamily: "gotham-book, sans-serif"
            }
        },

        MuiTextEdit: {
            root: {
                small: {
                    fontSize: "8px !important"
                }
            }
        },

        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border:              'none', // Remove the border
                    backgroundColor:     '#fff', // Set the background color
                    '.MuiDataGrid-cell': {
                        border: 'none' // Remove cell borders
                    },
                    '.MuiDataGrid-columnHeaders': {
                        backgroundColor: '#f5f5f5', // Column headers background
                        fontWeight:      'bold' // Make the header text bold
                    },
                    '.MuiDataGrid-row': {
                        '&:hover': {
                            backgroundColor: '#f5f5f5' // Row hover background color
                        }
                    }
                }
            }
        },

        MuiChip: {
            root: {
                color:           "var(--black-1)",
                backgroundColor: "transparent",
                justifyContent:  "left"
            },

            label:      {
                fontWeight:     "normal",
                paddingLeft:    "11px",
                paddingRight:   "3px",
                paddingTop:     "2px",

                "&.owner": {
                    fontWeight: "bold"
                },

                "&.fixed-width-font": {
                    fontFamily: "sourcecodepro-regular"
                }

            },

            deleteIcon: {
                color:          "var(--grey-4) !important",
                visibility:     "visible",
                marginRight:    "10px",
                marginLeft:     "4px",
                marginTop:      "2px",
                position:       "relative",
                height:         "16px",

                "&:hover": {
                    color:      "var(--cobalt-0) !important",
                    opacity:    1
                }
            },

            avatar: {
                fontSize: 11
            }
        },

        MuiButton: {
            root: {
                padding:      "10px 38px",
                borderRadius: 30,
                alignSelf:    "center",
                margin:       "0 auto",
                boxShadow:    "none",
                height:       39,
                fontSize:     16,
                lineHeight:   1.25
            },

            // "&:hover": {
            //     background: "none"
            // },

            sizeSmall: {
                height:     32,
                padding:    "6px 24px",
                fontSize:   14,
                lineHeight: 1
            },

            contained: {
                boxShadow: "none"
            },

            label: {
                whiteSpace: "nowrap"
            }
        },

        MuiCard: {
            root: {
                boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.2)"
            }
        },

        MuiDivider: {
            root: {
                height:          2,
                border:          "inset 2px",
                backgroundColor: "initial"
            }
        },

        MuiCircularProgress: {
            root: {
                color: "var(--teal) !important"
            }
        },

        MuiLinearProgress: {
            root: {
                margin:       "10px auto",
                height:       10,
                borderRadius: 5
            },

            colorSecondary: {
                backgroundColor: "var(--lightgrey-1)"
            },

            barColorSecondary: {
                background: "repeating-linear-gradient(120deg, rgba(255,255,255,0.15) 0 18px, transparent 18px 36px)"
            }
        },

        MuiFab: {
            label: {
                "&.tiny": {
                    marginTop: 2
                },
                marginTop:     0
            },

            root: {
                backgroundColor: "transparent",
                height:          40,
                width:           40,
                boxShadow:       "none",
                minHeight:       25,
                minWidth:        25,
                lineHeight:      null,

                "&.tiny":        {
                    height:   25,
                    width:    25,
                    fontSize: 13
                },

                "&.small": {
                    height: 30,
                    width:  30
                },

                "&.adornment": {
                    color:        "var(--cobalt-3)",

                    "&, &:hover": {
                        backgroundColor: "transparent"
                    },

                    "&:hover": {
                        color: "var(--black-1)"
                    }
                },

                "&.Mui-disabled": {
                    backgroundColor: "transparent",
                    opacity:         0.5
                },

                "&:active": {
                    boxShadow: "none"
                }
            }
        },

        MuiMenuItem: {
            root: {
                fontFamily:   "gotham-book, sans-serif",
                color:        "var(--black-1)",
                width:        "100%",
                borderRadius: 0,
                fontSize:     14
            },
            gutters: {
                paddingLeft:   19
            }
        },

        MuiListItemText: {
            root: {
                marginTop:      4,
                marginBottom:   4,
                marginLeft:     12,
                fontSize:       14,
                textTransform: "capitalize"
            },
            inset: {
                paddingLeft:     "56px !important"
            }
        },

        MuiListItem: {
            root: {
                width:          '100%',
                display:        'flex',
                position:       'relative',
                boxSizing:      'border-box',
                textAlign:      'left',
                alignItems:     'center',
                justifyContent: 'flex-start',
                textDecoration: 'none'
            },
            gutters: {
                paddingLeft:   19,
                paddingTop:    16,
                paddingBottom: 16
            }
        },

        MuiAvatar: {
            root: {
                fontFamily: "gotham-book, sans-serif",
                width:      40,
                height:     40,
                fontSize:   "16px !important",

                "&.small": {
                    background: "var(--teal)",
                    width:      24,
                    height:     24,
                    fontSize:   "11px !important"
                },

                "&.smallish": {
                    fontSize:   "12px !important"
                }
            },

            colorDefault: {
                backgroundColor: "var(--cobalt-2)"
            }
        },

        MuiAutocomplete: {
            paper: {
                borderRadius:       "4px !important",
                backgroundColor:    "var(--white)",
                boxShadow:          "1px 1px 2px var(--grey-4)",
                padding:            "4px 0",
                fontFamily:         "gotham-medium, sans-serif",
                fontSize:           14,
                width:             "95%"
            },

            listbox: {
                padding: "0"
            },

            loading: {
                padding: "6px 19px"
            },

            noOptions: {
                fontSize:        14,
                fontFamily:      "gotham-medium, sans-serif",
                padding:         "6px 19px"
            },

            input: {
                padding: "14px 19px 13px 19px !important"
            },

            option: {
                fontSize:        14,
                fontFamily:      "gotham-medium, sans-serif",
                color:           "var(--black-2)",
                padding:         "6px 19px !important"
            },

            inputRoot: {
                padding:    "0px !important",

                "&.slim": {
                    padding: 0
                }
            }
        },

        MuiInputBase: {
            input: {
                color:           "var(--black-1)",
                fontSize:        14,
                height:          '1.1876em',
                "&:placeholder": {
                },

                "&.slim": {
                    padding: "12px 19px 10px 19px"
                },
                "&.Mui-disabled": {
                    color:                      "var(--grey-4)",
                    WebkitTextFillColor:        'var(--grey-3)'
                }
            },

            root: {
                backgroundColor: "white"
            }
        },

        MuiFormControl: {
            root: {
                marginLeft:   0,
                marginRight:  0,
                marginBottom: 0
            }
        },

        MuiFormControlLabel: {
            root: {
                marginLeft:  0,
                marginRight: 0
            },
            labelPlacementStart: {
                marginLeft:    16,
                marginRight:   -11,
                flexDirection: "row-reverse"
            },
            labelPlacementTop: {
                marginLeft:    16,
                flexDirection: "column-reverse"
            },
            labelPlacementBottom: {
                marginLeft:    16,
                flexDirection: "column"
            },
            
            label: {
                color:      "var(--black-1)",
                fontSize:   12,
                fontFamily: "gotham-medium, sans-serif"
            }
        },

        MuiOutlinedInput: {
            root: {
                borderColor: "var(--grey-4)",
                fontFamily:  "gotham-book, sans-serif",
                lineHeight:  "16px",
                borderWidth: 2,
                marginTop:   1,

                "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--grey-4)"
                },

                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "var(--teal)"
                }
            },
            inputMultiline: {
                padding: 0
            },

            input: {
                padding:    "14px 19px",

                "&::placeholder": {
                    color:      "var(--grey-2)",
                    opacity:    1
                }
            },

            notchedOutline: {
                borderColor: "var(--grey-4)",
                borderWidth: 2
            },

            multiline: {
                fontFamily:  "gotham-book, sans-serif",
                padding:     "14px 19px"
            },

            adornedEnd: {
                paddingRight: 0
            }
        },

        MuiSelect: {
            select: {
                height:       'auto',
                overflow:     'hidden',
                minHeight:    '1.1876em',
                whiteSpace:   'nowrap',
                textOverflow: 'ellipsis',
                fontFamily:    "gotham-book, sans-serif",
                padding:       14,

                "&.slim": {
                    padding:       "11px 40px 11px 19px"
                },
                "&.jcField": {
                    padding:       "12px 10px 11px 19px"
                },

                // text class produces a select box that matches LicoTextField for size
                "&.text": {
                    height:        31,
                    paddingTop:    16,
                    paddingLeft:   16,
                    paddingBottom: 7
                },

                "&.bold": {
                    fontFamily:    "gotham-medium, sans-serif"
                }
            }
        },

        jobId: {
            color: "var(--lightgrey-2) !important;"
        },

        MuiSwitch: {

            switchBase: {
                '&.Mui-checked': {
                    color:              'white'
                },
                '&.Mui-disabled': {
                    color: 'var(--grey-4)'
                },
                '&.Mui-checked.Mui-disabled': {
                    color: 'var(--grey-4)'
                }
            },
            track: {
                // Controls default (unchecked) color for the track
                backgroundColor:        "var(--grey-2)",

                // Controls checked color for the track, disabled or not; both states
                // are displayed with reduced opactity, disabled controls more so.
                ".Mui-checked.Mui-checked + &": {
                    backgroundColor: 'var(--teal)'
                }
            }

        },

        MuiCheckbox: {
            root: {
                "&.Mui-checked": {
                    color: "var(--teal)"
                },

                // not sure about below color code. should be #50E3C2, but that needs to be confirmed.
                '&Mui-checked&:hover': {
                    backgroundColor: '#50E3C222'
                }
            }
        },

        MuiSnackbar: {
            anchorOriginTopCenter: {
                top:    "80px !important;"
            }
        },

        MuiTooltip: {
            tooltip: {
                backgroundColor:    'var(--black-1)',
                fontSize:           12
            },
            arrow: {
                color: 'var(--black-1)'
            }
        }
    }
};

const mui4Theme = adaptV4Theme( themeOptionsWithOverides );


const combinedTheme = {
    ...mui4Theme,
    components: {
        ...mui4Theme.components,
        MuiCssBaseline: {
            styleOverrides: (  ) => ({
                html: {
                    boxSizing:           'border-box',
                    WebkitFontSmoothing: 'antialiased',
                    MozOsxFontSmoothing: 'grayscale'
                },
                container: {
                    boxSizing: 'inherit'
                },
                strong: {
                    fontWeight: 700
                },
                body: {
                    color:           'rgba(0, 0, 0, 0.87)',
                    margin:          0,
                    fontSize:        '0.875rem',
                    fontFamily:      'Roboto, Helvetica, Arial, sans-serif',
                    fontWeight:      400,
                    lineHeight:      1.43,
                    letterSpacing:   '0.01071em',
                    backgroundColor: '#fafafa'
                },
                mediaPrint: {
                    body: {
                        backgroundColor: '#fff'
                    }
                },
                backdrop: {
                    body: {
                        backgroundColor: '#fafafa'
                    }
                }
            })
        }
        
    }
};

// Running into this issue I think : https://github.com/mui-org/material-ui/issues/16307
export const theme = createTheme( combinedTheme  );
