/********************************************************************************************************************
 * @file jobcard.js - job card selectors
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21/12/2021
 *********************************************************************************************************************/

import { isObject } from "@licoriceio/utils";

import { selectNameDataReady } from "./names.js";

export const selectCurrentJobCardId = state => selectNameDataReady( state ) ? state.jobcard.currentJobCardId : undefined;

export const selectNewJobDetails = state => {
    return state.jobcard.newJobDetails;
};

export const selectJobAppointments = state  => {
    return state.jobcard._sortedAppointments;
};

export const selectJobEngineers = state  => {
    return state.jobcard._engineersOptions;
};

export const selectJobUsers = state  => {
    return state.jobcard._usersOptions;
};

export const selectJobChecklist = state  => {
    return state.jobcard.checklist;
};
export const selectJobAssets = state  => {
    return state.jobcard.assetsMap;
};
export const selectJobHasNotes = state => {
    return state.jobcard.notes.length > 0 || isObject( state.jobcard.pendingDisplayNote );
};
export const selectJobHasAssets = state => {
    const assetMapKeys = Object.keys( state.jobcard.assetsMap )?.length;
    return assetMapKeys > 0;
};

export const selectJobNotes = state  => {

    const { jobcard: { currentJobCardId, notes, pendingDisplayNote } } = state;

    if ( pendingDisplayNote && pendingDisplayNote.jobId === currentJobCardId )
        return [ ...notes, pendingDisplayNote ];
    else
        return notes;
};
