/*********************************************************************************************************************
 * @file Search reducer
 * @author Nishtha Rajani <nrajani@licorice.io>
 * @since 2.0.0
 * @date 14-Sep-2023
 *********************************************************************************************************************/
import { StatusNames, integrationNames, UserRole } from '@licoriceio/constants';
import { has } from '@licoriceio/utils';
import dayjs from 'dayjs';
import { v4 as uuid } from "uuid";

import { GET, searchPanelChipType, uri } from '../../constants.js';
import {
    setActiveTabId,
    setAddToSearchPanel,
    setCalculateJobPage,
    setDecrementExternalJobCount,
    setFilterList, setSearchFilterString,
    setSearchPanel, setMeta,
    setPatchJob,
    setRemoveFromSearchPanel,
    setRemoveJob,
    setJobOffset,
    setSearchPanelHeight,
    setJobs,
    setTypeNames,
    setJobCardCreatedTab,
    setJobNotes,
    setRemoveFilterItem,
    setAddFilterItem
} from '../../redux/actions/index.js';
import { cacheType, requestCacheRecords } from '../../redux/reducers/cache.js';
import { setSomeTypeNames } from '../../redux/reducers/names.js';
import { ezRedux, makeMapById, sliceChangeAction } from '../../redux/reducerUtil.js';
import { abstractedCreateAuthRequest } from '../../services/util/baseRequests.js';
import { __ } from '../../utils/i18n.jsx';
import { saveToUserSession } from "../../utils/local-storage.js";

/**
 * @typedef {object}    Job
 * @property {boolean}  billable
 * @property {string}   companyId
 * @property {string}   companyName
 * @property {string}   createdOn
 * @property {string}   creatorId
 * @property {number}   estimatedTime
 * @property {string}   userId
 * @property {string}   priority
 * @property {string}   providerBoardId
 * @property {string}   providerJobId
 * @property {string}   sourceLastUpdated
 * @property {string}   state
 * @property {string}   title
 * @property {string}   type
 * @property {string}   description // initially undefined but loaded on demand after mouseover
 */


/**
 * @typedef SearchPanel
 * @property {number} activeTabId
 *  @property {number} searchPanelHeight
 * @property {Array<{
 *   id: number,
 *   jobMap: Record<string, Job>,
 *   sortedJobIds: null | Array<string>,
 *   filterString: string,
 *   jobOffset: number,
 *   numberVisibleJobs: number,
 *   filterList: Array<any>
 * }>} tabs
 */


/**
 * @typedef   {object} filterList
 *  @property {number} id
 *  @property {number} tabIndex
 *  @property {Array<FilterParam>} filterParams
 *  @property {Array<number>} filterResultJobIds
 *  */

/**
 * @typedef {object} FilterParam
 * @property {number} id
 * @property {object} company/user/status
 */


const startingId = uuid();
const initialState = Object.freeze({
    activeTabId:          startingId,
    searchPanelHeight:    0,
    tabs:                 [
        {
            id:                 startingId,
            jobMap:             {},
            sortedJobIds:       null,
            filterString:       '',
            jobOffset:          0,
            numberVisibleJobs:  0,
            filterList:         [],
            jobCardCreatedTab:  false
        }
    ]
});

const REOPENED_JOB_ICON = "inbox-in";
const NOT_SCHEDULED_ICON = "calendar";

const jobStatusMap = {
    [ StatusNames.NOT_SCHEDULED ]: {
        icon:    NOT_SCHEDULED_ICON,
        slashed: true
    },
    [ StatusNames.SCHEDULED ]: {
        icon: "calendar-tick"
    },
    [ StatusNames.IN_PROGRESS ]: {
        icon: "person-running"
    },
    [ StatusNames.WAIT_CLIENT ]: {
        icon: "user"
    },
    [ StatusNames.WAIT_PARTS ]: {
        icon: "puzzlePieceSimple"
    },
    [ StatusNames.WAIT_THIRD_PARTY ]: {
        icon: "building"
    },
    [ StatusNames.REOPENED_JOB ]: {
        icon: REOPENED_JOB_ICON
    },
    [ StatusNames.CANCELLED_JOB ]: {
        icon:    "folder",
        slashed: true
    },
    [ StatusNames.RESOLVED_JOB ]: {
        icon: "folder"
    },
    [ StatusNames.UNMAPPED_STATUS ]: {
        icon: "question-circle"
    }
};

/** services */

const _asyncFetchJobs = abstractedCreateAuthRequest( GET, uri.JOBS_BY );
const _asyncFetchJobNotes = abstractedCreateAuthRequest( GET, uri.JOB_NOTES );



/** selectors */

const selectSearchPanel = state => state.searchPanel?.tabs;
const selectActiveTabId = state => state.searchPanel?.activeTabId;
const selectActiveTabIndex = state => {
    const activeTabId = state?.searchPanel?.activeTabId;
    const index = state?.searchPanel?.tabs?.findIndex( t => t.id === activeTabId );
    if ( index >= 0 )
        return index;
    else
        return 0;
};


// rename to tab ffs
const selectTabState = ( state, id ) => {
    const tab = state?.searchPanel?.tabs?.find( t => t.id === id );
    const { jobMap, sortedJobIds, filterString, jobOffset, numberVisibleJobs, filterList, total } = tab;
    return {
        jobMap:         jobMap || {},
        sortedJobIds:   sortedJobIds || [],
        filterString,
        jobOffset,
        numberVisibleJobs,
        filterList,
        total
    };
};

/**  thunks  */

const addToSearchPanel = payload => ( dispatch, getState ) => {
    const  { jobCardCreatedTab = false, filterList } = payload;
    const { jobcard: { jobStatus },  user: { userId, role }  } = getState();
    const notScheduledStatus =  jobStatus.nameToId[ StatusNames.NOT_SCHEDULED ];

    dispatch( setAddToSearchPanel({ 
        notScheduledStatus: { id: notScheduledStatus, label: notScheduledStatus.name },
        jobCardCreatedTab, 
        filterList,
        userId, 
        role
    }) );
};

const addNewTabWithClient = payload => ( dispatch, getState ) => {

    const { searchPanel: { tabs } } = getState();

    // payload for new or reused tab is the same
    const filterList = [
        payload.userId != null
            ? { type:  searchPanelChipType.USER, id: payload.userId, label: payload.name }
            : { type:  searchPanelChipType.COMPANY, id: payload.companyId, label: payload.companyName } 
    ];

    // can we reuse an existing autotab? If it's still marked as "jobCardCreated" then it hasn't been changed
    const autoTab = tabs.find( tab => tab.jobCardCreatedTab );
    if ( autoTab )
    {
        dispatch( setFilterList({ selectedFilterList: filterList, id: autoTab.id }) );
        dispatch( setActiveTabId( autoTab.id ) );
        dispatch( getJobsForTab( autoTab.id ) );
        return;
    }

    // we can get payloads with user id or not, depending on whether the jobcard company selector was used to pick a company 
    // or a user (and hence a company). If a user was selected, we want the autotab to track that user.
    dispatch( setMeta({ searchPanelShown: true }) );
    dispatch( addToSearchPanel({ 
        jobCardCreatedTab:  true,
        filterList
    }) );
    // 73 is the height of the header
    let newHeight = window.innerHeight - 73;
    if ( newHeight < 0 )
        newHeight = window.innerHeight;
    dispatch( windowHeightChanged( newHeight ) );

};

const removeAutoClientTab = () => ( dispatch, getState ) => {
    const { searchPanel: { tabs } } = getState();
    const autoTabIndex = tabs.findIndex( tab => tab.jobCardCreatedTab );
    if ( autoTabIndex >= 0 )
        dispatch( setRemoveFromSearchPanel( autoTabIndex ) );
};

const getJobsForTab = ( id ) => ( dispatch, getState ) => {
    const { searchPanel } = getState();
    const { activeTabId, tabs } = searchPanel;
    id ??= activeTabId;
    const index = tabs.findIndex( t => t.id === id );
    if ( index < 0 )
        return;
    const filterList = tabs[ index ]?.filterList;
    const filterString = tabs[ index ]?.filterString;

    const pageSize = tabs[ index ]?.numberVisibleJobs || 10;
    const page = Math.ceil( tabs[ index ]?.jobOffset / pageSize );

    let userId = [];
    let engineerId = [];
    let companyId = [];
    let statusId = [];
    let terms = [];

    const filterActions = {
        [ searchPanelChipType.USER ]: item => ( item.role === UserRole.engineer ? engineerId : userId ).push( item.id ),
        [ searchPanelChipType.TEAM ]: item => 
            item.members.forEach( 
                member => ( member.user.role === UserRole.engineer ? engineerId : userId ).push( member.user.userId ) ),
        [ searchPanelChipType.COMPANY ]: item => companyId.push( item.id ),
        [ searchPanelChipType.STATUS ]:  item => statusId.push( item.id ),
        [ searchPanelChipType.ICON ]:    item => statusId.push( item.statusId ),
        [ searchPanelChipType.STRING ]:  item => terms.push( item.label )
    };

    // Iterate through the filterList array to get the userIds, companyIds, statusIds and free text for query
    filterList.forEach( item => filterActions[ item.type ]( item ) );

    if ( filterString )
        terms.push( filterString );
    dispatch( getJobs({ userId, engineerId, companyId, statusId, terms, pageSize, page }) );
};


const getJobs = payload => ( dispatch, getState ) => {
    const { userId, companyId, statusId, terms, engineerId, pageSize = 10, page = 0 } = payload;
    const queryParams = { userId, companyId, statusId, terms, engineerId };

    // Function to convert the query params object to a JSON-like structure for the fetch call

    const createQueryParams = params =>
        Object.fromEntries(
            Object.entries( params )
                .filter( ([ , value ]) => value !== '' && value != null && ( !Array.isArray( value ) || value.length > 0 ) )
                .map( ([ key, value ]) => [
                    key,
                    Array.isArray( value ) ? value.filter( v => v != null ) : value
                ])
        );
    const validParams = createQueryParams( queryParams );

    _asyncFetchJobs( undefined, getState().auth, undefined, { pageSize, page, ...validParams }).then( result => {
        dispatch( setCalculateJobPage() );
        dispatch( setJobs({ data: result.payload, total: result.count }) );
    });
};

const getJobNotes = ( tabId, jobId ) => ( dispatch, getState ) => {

    // check for job notes already fetched
    const { searchPanel: { tabs } } = getState();
    const tab = tabs.find( t => t.id === tabId );
    const job = tab.jobMap[ jobId ];
    if ( job && job.notes ) return;

    // we're copying the request from the job card request, but we're also doing other stuff
    // after the request which would need a thunk to fit into our genericRequest setup, so we
    // can't just re-use the job card request and action, so leave this as is for now.
    _asyncFetchJobNotes( undefined, getState().auth, [ jobId ], {
        pageSize:     10,
        ignoreActive: true,
        related:      [ 'note->user', 'note(timeLogId)->?timeLog' ],
        fields:       [ 'note.*', 'user.name', 'user.preferences', 'user.role', 'timeLog.billable' ]
    }).then( result => {
        if ( !result.hasError ) {
            dispatch( setPatchJob({
                jobId,
                notes: result.payload,
                tabId: tabId
            }) );

            const userIds = result.payload.map( note => note.userId );
            dispatch( requestCacheRecords({ type: cacheType.USER, ids: userIds }) );
        }
    });
};

const windowHeightChanged = newHeight => ( dispatch, getState ) => {
    if ( newHeight < 0 ) 
        newHeight = window.innerHeight;
    
    dispatch( setSearchPanelHeight( newHeight ) );
    if ( getState().meta.searchPanelShown )
        dispatch( setCalculateJobPage() );
};

const filterStringChanged = ( filterString, id ) => dispatch => {
    dispatch( setSearchFilterString( filterString ) );
    dispatch( getJobsForTab( id ) );
    dispatch( setCalculateJobPage() );
};

const checkForAddedJobThunk = providerJobId => ( dispatch, getState ) => {
    const { job: { jobMap } } = getState();
    if ( providerJobId in jobMap ) {
        dispatch( setRemoveJob( providerJobId ) );
        dispatch( setDecrementExternalJobCount() );
    }
};

/** reducers */

const setJobsReducer = ( draft, payload ) => {
    const { data, total } = payload;
    const index = draft?.tabs?.findIndex( t => t.id === draft.activeTabId );
    if ( index === -1 ) return;
    draft.tabs[ index ].jobMap = data.reduce( makeMapById( 'jobId' ), {});
    draft.tabs[ index ].total = total || 0;
    draft.tabs[ index ].sortedJobIds = data
        .slice()
        .sort( ( a, b ) =>
            b.hasOwnProperty( 'createdOn' )
                ? b.createdOn.localeCompare( a.createdOn )
                : Number( a.providerJobId ) - Number( b.providerJobId )
        )
        .map( job => job.jobId );

};

const patchJobReducer = ( draft, args ) => {
    const { jobId, notes, tabId } = args;
    const index = draft?.tabs.findIndex( t => t.id === tabId );
    if ( index < 0 )
        return;
    const tab = draft?.tabs[ index ];
    const job = tab.jobMap[ jobId ];
    if ( job ) {
        if ( notes ) {
            job.notes = notes
                .sort( ( a, b ) => b.createdOn.localeCompare( a.createdOn ) )
                .reduce( ( acc, cur ) => {
                    const noteDay = dayjs( cur.createdOn ).format( 'D MMMM YYYY' );
                    if ( !has( acc, noteDay ) )
                        acc[ noteDay ] = [];
                    acc[ noteDay ].push( cur );
                    return acc;
                }, {});
        }
    }
};

const JOB_HEADER_HEIGHT = 180;    // inc top margin and space 
const JOB_PAGER_HEIGHT = 55;     // inc bottom margin
const JOB_HEIGHT = 133;

const calculateJobPageReducer = ( draft ) => {

    // we've stored the size so we can set the search panel's minimum height to match the pegboard.
    // We can also work out the maximum number of jobs that can be displayed at this size.
    // Note that we can display more jobs on the first page if there is no pager to be displayed.

    // this calculation should take the existing filter chips into account, which differ for each tab.
    // Without looking into the DOM, it's not trivial to work out how many vertical space is consumed for 
    // a given number of chips, since they can have different lengths.
    // One thing we could do is give an absolute size to the job list section (from numberVisibleJobs * JOB_HEIGHT) 
    // and let it add a scroll bar as required. That would give us an escape valve if/when our heuristic for 
    // converting number of chips to vertical space fell down, eg if several over-long chips were added.
    const searchPanelHeight = draft.searchPanelHeight;
    const absoluteMax = Math.max( Math.floor( ( searchPanelHeight - JOB_HEADER_HEIGHT ) / JOB_HEIGHT ), 1 );
    const pagerMax = Math.max( Math.floor( ( searchPanelHeight - ( JOB_HEADER_HEIGHT + JOB_PAGER_HEIGHT ) ) / JOB_HEIGHT ), 1 );

    // resizing doesn't change offset unless we can move to a single page;
    // if we're display records 4-6 of 10 and we grow the page, we simply
    // display 4-7 or whatever, we don't adjust the offset to start from 5 because
    // the front page can now hold 1-4. Jobs disappearing is bad.
    draft.tabs.forEach( ( draftItem ) => {
        if ( draftItem?.total <= absoluteMax )
            draftItem.numberVisibleJobs = absoluteMax;
        else
            draftItem.numberVisibleJobs = pagerMax;
    });
};

const updateFilterListReducer = ( draft, payload ) => {
    const { selectedFilterList, id = draft.activeTabId } = payload;

    const index = draft?.tabs?.findIndex( t => t.id === id );
    if ( index >= 0 ) {
        draft.tabs[ index ].filterList = selectedFilterList;
        saveSearchPanelToUserSession( draft );
    }
};

const saveSearchPanelToUserSession = draft => {
    const { tabs } = draft;
    const filteredTabs = tabs.map( tab => {
        const { id, filterString, filterList, jobCardCreatedTab } = tab;
        return  {
            id,
            filterString,
            filterList,
            jobCardCreatedTab,
            jobMap:            {},
            sortedJobIds:      null,
            jobOffset:         0,
            numberVisibleJobs: 0
        };
    });

    const searchPanel = {
        tabs:        filteredTabs,
        activeTabId: draft.activeTabId
    };
    saveToUserSession({ searchPanel });
};

const removeFromSearchPanelReducer = ( draft, tabIndex ) => {
    if ( tabIndex >= 0 ) {
        draft.tabs.splice( tabIndex, 1 );
        const newActiveTabIdIndex = tabIndex > 0 ? tabIndex - 1 : 0;
        draft.activeTabId = draft.tabs.length > 0 ? draft?.tabs[ newActiveTabIdIndex ]?.id : null;
        saveSearchPanelToUserSession( draft );
    }
};

const addToSearchPanelReducer = ( draft, payload ) => {
    const tabId = uuid();
    const {  jobCardCreatedTab, userId, role, ...restPayload } = payload;
    draft.activeTabId = tabId;
    draft.searchPanelHeight = 0;
    draft.tabs.push({
        jobCardCreatedTab,
        id:                   tabId,
        jobMap:               {},
        sortedJobIds:         null,
        filterString:         '',
        jobOffset:            0,
        numberVisibleJobs:    0,
        filterList:           payload.filterList ?? [
            {
                type:           'icon',
                icon:           'hand',
                label:          __( 'Assigned to me' ),
                isFilterButton: true,
                userId:         userId,
                role:           role
            },
            {
                type:           'status',
                icon:           'calendar',
                slashed:        true,
                isFilterButton: true,
                ...restPayload.notScheduledStatus
            }
        ]
    });
    saveSearchPanelToUserSession( draft );
};

const updateActiveTabIdReducer = ( draft, id ) => {
    draft.activeTabId = id;
    saveSearchPanelToUserSession( draft );
};

const getActiveTab = draft => draft.tabs.find( t => t.id === draft.activeTabId );

const doActionOnActiveTab = ( draft, action, ...rest ) => {
    const activeTab = getActiveTab( draft );
    if ( activeTab )
    {
        action( activeTab, rest );
        saveSearchPanelToUserSession( draft );
    }
};

const jobCardNotesReducer = ( draft, notes ) => {
    // when a job card is opened and the notes are fetched, check if we should update our cached job notes.
    // this provides 2 methods for displaying the correct notes in the tooltip; open the job card(s) concerned, 
    // or refresh the search panel page.
    if ( notes.length > 0 && draft.searchPanelShown )
    {
        const activeTab = getActiveTab( draft );
        if ( activeTab && has( activeTab.jobMap, notes[ 0 ].jobId ) )
        {
            // use the usual reducer to update note data
            patchJobReducer( draft, { 
                jobId:  notes[ 0 ].jobId,
                tabId:  activeTab.id,
                notes
            });
        }
    }
};

const addNewFilterItem = ( tab, newItem ) => {
    if ( !tab.filterList.some( item => item.type === newItem.type && item.label === newItem.label ) )
    {
        tab.filterList = [ ...tab.filterList, newItem ];
        tab.jobCardCreatedTab = false;
    }
};

const removeFilterItem = ( tab, oldItem ) => {
    tab.filterList = tab.filterList.filter( item => item.type != oldItem.type || item.label != oldItem.label );
    tab.jobCardCreatedTab = false;
};

const applyJobChange = ( tab, payload ) => {

    // this is the slice action that triggers the db update (and the change to the real jobMap in the store).
    // Since we're currently having our own jobMap that only applies to the current page of a tab,
    // we'll echo any changes to there.
    // (There are arguments for not doing this duplication but it won't be trivial to change this)
    
    // there are 2 actions generated; we want the one without the 'field' property (which is the db response).
    // We also check if the job appears in the active page.
    if ( has( payload, 'updates' ) && !has( payload, 'field' ) && has( tab.jobMap, payload.id ) )
        Object.assign( tab.jobMap[ payload.id ], { ...payload.updates });
};

const reducers = {
    [ setJobs ]:                        setJobsReducer,
    [ setPatchJob ]:                    patchJobReducer,
    [ setJobOffset ]:                 ( draft, payload ) => doActionOnActiveTab( draft, tab => tab.jobOffset = payload ),
    [ setSearchPanelHeight ]:    ( draft, searchPanelHeight ) => {
        draft.searchPanelHeight = searchPanelHeight;
    },
    [ setCalculateJobPage ]:            calculateJobPageReducer,
    [ setRemoveFromSearchPanel ]:       removeFromSearchPanelReducer,
    [ setAddToSearchPanel ]:            addToSearchPanelReducer,
    [ setFilterList ]:                  updateFilterListReducer,
    [ setActiveTabId ]:                 updateActiveTabIdReducer,
    [ setSearchPanel ]:                 ( draft, payload ) => {
        const { tabs, activeTabId } = payload;
        draft.tabs = tabs;
        draft.activeTabId = activeTabId;
    },
    [ setSearchFilterString ]:          ( draft, payload ) => doActionOnActiveTab( draft, tab => tab.filterString = payload ),
    [ setTypeNames ]:                   setSomeTypeNames( integrationNames.JOB_STATUS ),
    [ setJobCardCreatedTab ]:           ( draft, payload ) => doActionOnActiveTab( draft, tab => tab.jobCardCreatedTab = payload ),
    [ setJobNotes ]:                    jobCardNotesReducer,
    [ setMeta ]:                        ( draft, payload ) => { if ( has( payload, 'searchPanelShown' ) ) draft.searchPanelShown = payload.searchPanelShown; },
    [ setAddFilterItem ]:               ( draft, payload ) => doActionOnActiveTab( draft, tab => addNewFilterItem( tab, payload ) ),
    [ setRemoveFilterItem ]:            ( draft, payload ) => doActionOnActiveTab( draft, tab => removeFilterItem( tab, payload ) ),
    [ sliceChangeAction( 'job' ) ]:     ( draft, payload ) => doActionOnActiveTab( draft, tab => applyJobChange( tab, payload ) )
};

export {
    getJobs,
    getJobsForTab,
    selectTabState,
    windowHeightChanged,
    getJobNotes,
    filterStringChanged,
    checkForAddedJobThunk,
    selectSearchPanel,
    addToSearchPanel,
    selectActiveTabId,
    selectActiveTabIndex,
    addNewTabWithClient,
    removeAutoClientTab,
    jobStatusMap,
    REOPENED_JOB_ICON,
    NOT_SCHEDULED_ICON
};

/** the default export is the reducer function, which is passed to combineReducers. */
export default ezRedux( reducers, initialState );
