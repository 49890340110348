/********************************************************************************************************************
 * @file Actions for the scheduling store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setToken = createAction( 'SCHEDULE.TOKEN/SET' );
export const setScheduleError = createAction( 'SCHEDULE.ERROR/SET' );
export const setProgressAction = createAction( 'SCHEDULE.PROGRESS/SET' );
export const setAppointment = createAction( 'SCHEDULE.APPOINTMENT/SET' );
export const setEngineerId = createAction( 'SCHEDULE.ENGINEER_ID/SET' );
export const setStartTime = createAction( 'SCHEDULE.START_TIME/SET' );
export const setJobTitle = createAction( 'SCHEDULE.JOB_TITLE/SET' );
export const setJobDescription = createAction( 'SCHEDULE.JOB_DESCRIPTION/SET' );
export const setJobPriority = createAction( 'SCHEDULE.JOB_PRIORITY/SET' );
export const setWeekStart = createAction( 'SCHEDULE.WEEK_START/SET' );
export const setWeekData = createAction( 'SCHEDULE.WEEK_DATA/SET' );
export const setCurrentWeekData = createAction( 'SCHEDULE.CURRENT_WEEK_DATA/SET' );
export const setSendAppointment = createAction( 'SCHEDULE.SEND_APPOINTMENT/SET' );
export const setSettingsSchedule = createAction( 'SCHEDULE.SETTINGS/SET' );
export const setFoundEligibles = createAction( 'SCHEDULE.FOUND_ELIGIBLES/SET' );
export const setFixedEngineer = createAction( 'SCHEDULE.FIXED_ENGINEER/SET' );

