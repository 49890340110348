import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { addTagRequest } from '../../redux/genericThunks.js';
import { boardTypeDropdown, boardTypeLabel } from '../../scss/TagSelect.module.scss';

import { LicoSelect } from './index.js';

const TagSelect = props => {
    const {
        name,
        tooltip,
        tagType,
        tagOwnerId,
        options,
        addTagRequest
    } = props;

    return (
        <LicoSelect
            name={name}
            label=""
            tooltip={tooltip}
            onSelect={arg => {
                const option = options.find( o => o.id === arg.target.value );
                addTagRequest({ 
                    data:   { tagType, tagName: option.label, providerId: arg.target.value },
                    tagOwnerId
                });
            }}
            inlineLabel
            inputClassName={boardTypeDropdown}
            labelClassName={boardTypeLabel}
            noMargins
            rounded
            addEmptyOption
            options={options}
        />

    );
};

TagSelect.propTypes = {
    name:                       PropTypes.string.isRequired,
    tooltip:                    PropTypes.string,
    tagType:                    PropTypes.string.isRequired,
    tagOwnerId:                 PropTypes.string.isRequired,
    options:                    PropTypes.array.isRequired,
    addTagRequest:                PropTypes.func.isRequired
};
const mapDispatchToProps = {
    addTagRequest
};

export default connect( null, mapDispatchToProps )( TagSelect );
export { TagSelect };
