/* eslint-disable max-lines */
/** ******************************************************************************************************************
 * This file was automatically generated so don't make any changes here, or they'll get wiped out. To generate an
 * updated version of this file, run `pnpm run build`.
 *
 * This file was generated by `codegen/generators/gen-sync-rels.js`.
 * 
 * @author Julian Jensen <jjensen@licorice.io>
 *********************************************************************************************************************/
import { RelationHelpers } from './rel-funcs.js';

const rxUUID = /^[0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12}/i;
const rxAny = /^.+$/s;
const rxUserRole = /^(?:user|engineer)$/;

const ucf = s => ( typeof s === 'string' && s.length > 0 ) ? s[ 0 ].toUpperCase() + s.substring( 1 ) : s;
const isObj = o => typeof o === 'object' && !Array.isArray( o ) && o !== null;
const emptyObj = ( o, nullish = false ) => Object.keys( o ).every( k => nullish ? o[ k ] == null : o[ k ] === void 0 );
const isEmpty = ( x, nullish = true ) => x == null || ( Array.isArray( x ) && x.length === 0 ) || ( isObj( x ) && emptyObj( x, nullish ) ) || ( typeof x === 'string' && x === "" );

class DbstateRelation extends RelationHelpers {
    tableName = 'dbstate';
    modelName = 'Dbstate';
    correlate = null;
    notify = false;
    idColumn = 'dbstateId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'DbstateServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [];
    fields = [
        'dbstateId',
        'revision',
        'lastUserCheckDate',
        'namesInitialized',
        'integrationType',
        'flags',
        'syncRun',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {};
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const dbstate = new DbstateRelation();

class CompanyRelation extends RelationHelpers {
    tableName = 'company';
    modelName = 'Company';
    correlate = 'companies';
    notify = false;
    idColumn = 'companyId';
    providerIdColumn = 'providerCompanyId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'CompanyServices';
    converter = 'company';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [ 'companyName' ];
    fields = [
        'companyId',
        'companyName',
        'licoricePrimary',
        'providerCompanyId',
        'contactInfo',
        'version',
        'createdOn',
        'updatedOn',
        'contactId',
        'companyStatus'
    ];
    jsonFields = [
        'contactInfo',
        'version'
    ];
    relations = {
        company_CompanyStatusNote: {
            target: 'companyStatusNote',
            join:   {
                from: 'company.companyId',
                to:   'companyStatusNote.companyId'
            }
        },
        company_Job: {
            target: 'job',
            join:   {
                from: 'company.companyId',
                to:   'job.companyId'
            }
        },
        company_Asset: {
            target: 'asset',
            join:   {
                from: 'company.companyId',
                to:   'asset.companyId'
            }
        },
        company_CompanyUser: {
            target: 'companyUser',
            join:   {
                from: 'company.companyId',
                to:   'companyUser.companyId'
            }
        },
        company_CompanySite: {
            target: 'companySite',
            join:   {
                from: 'company.companyId',
                to:   'companySite.companyId'
            }
        },
        company_User: {
            target: 'user',
            join:   {
                from: 'company.contactId',
                to:   'user.contactId'
            }
        },
        company_LicoriceName: {
            target: 'licoriceName',
            join:   {
                from: 'company.companyStatus',
                to:   'licoriceName.companyStatus'
            }
        },
        company_CompanyStatusNote_LicoriceName_Company: {
            target: 'licoriceName',
            join:   {
                from:    'company.companyId',
                to:      'licoriceName.statusId',
                through: {
                    from: 'companyStatusNote.companyId',
                    to:   'companyStatusNote.statusId'
                }
            },
            through: 'companyStatusNote'
        },
        company_Job_User_Company: {
            target: 'user',
            join:   {
                from:    'company.companyId',
                to:      'user.userId',
                through: {
                    from: 'job.companyId',
                    to:   'job.userId'
                }
            },
            through: 'job'
        },
        company_Job_User_Company1: {
            target: 'user',
            join:   {
                from:    'company.companyId',
                to:      'user.creatorId',
                through: {
                    from: 'job.companyId',
                    to:   'job.creatorId'
                }
            },
            through: 'job'
        },
        company_Job_User_Company2: {
            target: 'user',
            join:   {
                from:    'company.companyId',
                to:      'user.contactId',
                through: {
                    from: 'job.companyId',
                    to:   'job.contactId'
                }
            },
            through: 'job'
        },
        company_Job_LicoriceName_Company: {
            target: 'licoriceName',
            join:   {
                from:    'company.companyId',
                to:      'licoriceName.statusId',
                through: {
                    from: 'job.companyId',
                    to:   'job.statusId'
                }
            },
            through: 'job'
        },
        company_Asset_User_Company: {
            target: 'user',
            join:   {
                from:    'company.companyId',
                to:      'user.contactId',
                through: {
                    from: 'asset.companyId',
                    to:   'asset.contactId'
                }
            },
            through: 'asset'
        },
        company_Asset_Site_Company: {
            target: 'site',
            join:   {
                from:    'company.companyId',
                to:      'site.siteId',
                through: {
                    from: 'asset.companyId',
                    to:   'asset.siteId'
                }
            },
            through: 'asset'
        },
        company_Asset_Manufacturer_Company: {
            target: 'manufacturer',
            join:   {
                from:    'company.companyId',
                to:      'manufacturer.manufacturerId',
                through: {
                    from: 'asset.companyId',
                    to:   'asset.manufacturerId'
                }
            },
            through: 'asset'
        },
        company_Asset_Sla_Company: {
            target: 'sla',
            join:   {
                from:    'company.companyId',
                to:      'sla.slaId',
                through: {
                    from: 'asset.companyId',
                    to:   'asset.slaId'
                }
            },
            through: 'asset'
        },
        company_Asset_AssetType_Company: {
            target: 'assetType',
            join:   {
                from:    'company.companyId',
                to:      'assetType.assetTypeId',
                through: {
                    from: 'asset.companyId',
                    to:   'asset.assetTypeId'
                }
            },
            through: 'asset'
        },
        company_Asset_AssetStatus_Company: {
            target: 'assetStatus',
            join:   {
                from:    'company.companyId',
                to:      'assetStatus.assetStatusId',
                through: {
                    from: 'asset.companyId',
                    to:   'asset.assetStatusId'
                }
            },
            through: 'asset'
        },
        company_CompanyUser_User_Company: {
            target: 'user',
            join:   {
                from:    'company.companyId',
                to:      'user.userId',
                through: {
                    from: 'companyUser.companyId',
                    to:   'companyUser.userId'
                }
            },
            through: 'companyUser'
        },
        company_CompanySite_Site_Company: {
            target: 'site',
            join:   {
                from:    'company.companyId',
                to:      'site.siteId',
                through: {
                    from: 'companySite.companyId',
                    to:   'companySite.siteId'
                }
            },
            through: 'companySite'
        }
    };
    validations = {
        companyName: rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerCompanyId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = null;
    conflictFields = [ 'providerCompanyId' ];
}

export const company = new CompanyRelation();

class ProviderRelation extends RelationHelpers {
    tableName = 'provider';
    modelName = 'Provider';
    correlate = null;
    notify = false;
    idColumn = 'providerId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'ProviderServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'displayName',
        'connectorName',
        'integrationData',
        'namespace'
    ];
    fields = [
        'providerId',
        'displayName',
        'fields',
        'connectorName',
        'integrationData',
        'namespace',
        'connected',
        'integrated',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [
        'fields',
        'integrationData'
    ];
    relations = {};
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = [];
    conflictFields = [
        'connectorName',
        'namespace'
    ];
}

export const provider = new ProviderRelation();

class BaseProviderRelation extends RelationHelpers {
    tableName = 'baseProvider';
    modelName = 'BaseProvider';
    correlate = null;
    notify = false;
    idColumn = 'baseProviderId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'BaseProviderServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [];
    fields = [
        'baseProviderId',
        'displayName',
        'fields',
        'connectorName',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'fields' ];
    relations = {};
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = [];
    conflictFields = [
        'displayName',
        'connectorName'
    ];
}

export const baseProvider = new BaseProviderRelation();

class UserRelation extends RelationHelpers {
    tableName = 'user';
    modelName = 'User';
    correlate = 'users';
    notify = false;
    idColumn = 'userId';
    providerIdColumn = 'providerUserId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [ 'activationDate' ];
    controllerName = 'UserServices';
    converter = '.role';
    ignore = [
        'active',
        'contactInfo',
        'password',
        'mfa',
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'name',
        'role'
    ];
    fields = [
        'userId',
        'activationDate',
        'active',
        'contactInfo',
        'title',
        'name',
        'loginEmail',
        'password',
        'mfa',
        'role',
        'eligible',
        'admin',
        'preferences',
        'version',
        'loginAttempts',
        'eula',
        'privacyPolicy',
        'termsConditions',
        'providerUserId',
        'providerUserIdentifier',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [
        'contactInfo',
        'preferences',
        'version'
    ];
    relations = {
        user_Company: {
            target: 'company',
            join:   {
                from: 'user.contactId',
                to:   'company.contactId'
            }
        },
        user_Job1: {
            target: 'job',
            join:   {
                from: 'user.userId',
                to:   'job.userId'
            }
        },
        user_Job3: {
            target: 'job',
            join:   {
                from: 'user.creatorId',
                to:   'job.creatorId'
            }
        },
        user_Job: {
            target: 'job',
            join:   {
                from: 'user.contactId',
                to:   'job.contactId'
            }
        },
        user_Appointment1: {
            target: 'appointment',
            join:   {
                from: 'user.creatorId',
                to:   'appointment.creatorId'
            }
        },
        user_Appointment3: {
            target: 'appointment',
            join:   {
                from: 'user.userId',
                to:   'appointment.userId'
            }
        },
        user_Appointment: {
            target: 'appointment',
            join:   {
                from: 'user.previousUserId',
                to:   'appointment.previousUserId'
            }
        },
        user_CalendarEvent: {
            target: 'calendarEvent',
            join:   {
                from: 'user.userId',
                to:   'calendarEvent.userId'
            }
        },
        user_Token1: {
            target: 'token',
            join:   {
                from: 'user.userId',
                to:   'token.userId'
            }
        },
        user_Token: {
            target: 'token',
            join:   {
                from: 'user.recipientId',
                to:   'token.recipientId'
            }
        },
        user_Notification: {
            target: 'notification',
            join:   {
                from: 'user.userId',
                to:   'notification.userId'
            }
        },
        user_TimeLog: {
            target: 'timeLog',
            join:   {
                from: 'user.userId',
                to:   'timeLog.userId'
            }
        },
        user_Checklist: {
            target: 'checklist',
            join:   {
                from: 'user.userId',
                to:   'checklist.userId'
            }
        },
        user_Note: {
            target: 'note',
            join:   {
                from: 'user.userId',
                to:   'note.userId'
            }
        },
        user_Asset: {
            target: 'asset',
            join:   {
                from: 'user.contactId',
                to:   'asset.contactId'
            }
        },
        user_Tag: {
            target: 'tag',
            join:   {
                from: 'user.userId',
                to:   'tag.userId'
            }
        },
        user_TagUser: {
            target: 'tagUser',
            join:   {
                from: 'user.userId',
                to:   'tagUser.userId'
            }
        },
        user_CompanyUser: {
            target: 'companyUser',
            join:   {
                from: 'user.userId',
                to:   'companyUser.userId'
            }
        },
        user_UserJob: {
            target: 'userJob',
            join:   {
                from: 'user.userId',
                to:   'userJob.userId'
            }
        },
        user_UserAppointment: {
            target: 'userAppointment',
            join:   {
                from: 'user.userId',
                to:   'userAppointment.userId'
            }
        },
        user_NotificationUnviewed: {
            target: 'notificationUnviewed',
            join:   {
                from: 'user.userId',
                to:   'notificationUnviewed.userId'
            }
        },
        user_History: {
            target: 'history',
            join:   {
                from: 'user.userId',
                to:   'history.userId'
            }
        },
        user_UserSite: {
            target: 'userSite',
            join:   {
                from: 'user.userId',
                to:   'userSite.userId'
            }
        },
        user_Job_Company_User: {
            target: 'company',
            join:   {
                from:    'user.userId',
                to:      'company.companyId',
                through: {
                    from: 'job.userId',
                    to:   'job.companyId'
                }
            },
            through: 'job'
        },
        user_Job_Company_Creator: {
            target: 'company',
            join:   {
                from:    'user.creatorId',
                to:      'company.companyId',
                through: {
                    from: 'job.creatorId',
                    to:   'job.companyId'
                }
            },
            through: 'job'
        },
        user_Job_Company_Contact: {
            target: 'company',
            join:   {
                from:    'user.contactId',
                to:      'company.companyId',
                through: {
                    from: 'job.contactId',
                    to:   'job.companyId'
                }
            },
            through: 'job'
        },
        user_Job_User_User: {
            target: 'user',
            join:   {
                from:    'user.userId',
                to:      'user.creatorId',
                through: {
                    from: 'job.userId',
                    to:   'job.creatorId'
                }
            },
            through: 'job'
        },
        user_Job_User_Creator: {
            target: 'user',
            join:   {
                from:    'user.creatorId',
                to:      'user.userId',
                through: {
                    from: 'job.creatorId',
                    to:   'job.userId'
                }
            },
            through: 'job'
        },
        user_Job_User_User1: {
            target: 'user',
            join:   {
                from:    'user.userId',
                to:      'user.contactId',
                through: {
                    from: 'job.userId',
                    to:   'job.contactId'
                }
            },
            through: 'job'
        },
        user_Job_User_Contact: {
            target: 'user',
            join:   {
                from:    'user.contactId',
                to:      'user.userId',
                through: {
                    from: 'job.contactId',
                    to:   'job.userId'
                }
            },
            through: 'job'
        },
        user_Job_LicoriceName_User: {
            target: 'licoriceName',
            join:   {
                from:    'user.userId',
                to:      'licoriceName.statusId',
                through: {
                    from: 'job.userId',
                    to:   'job.statusId'
                }
            },
            through: 'job'
        },
        user_Job_User_Creator1: {
            target: 'user',
            join:   {
                from:    'user.creatorId',
                to:      'user.contactId',
                through: {
                    from: 'job.creatorId',
                    to:   'job.contactId'
                }
            },
            through: 'job'
        },
        user_Job_User_Contact1: {
            target: 'user',
            join:   {
                from:    'user.contactId',
                to:      'user.creatorId',
                through: {
                    from: 'job.contactId',
                    to:   'job.creatorId'
                }
            },
            through: 'job'
        },
        user_Job_LicoriceName_Creator: {
            target: 'licoriceName',
            join:   {
                from:    'user.creatorId',
                to:      'licoriceName.statusId',
                through: {
                    from: 'job.creatorId',
                    to:   'job.statusId'
                }
            },
            through: 'job'
        },
        user_Job_LicoriceName_Contact: {
            target: 'licoriceName',
            join:   {
                from:    'user.contactId',
                to:      'licoriceName.statusId',
                through: {
                    from: 'job.contactId',
                    to:   'job.statusId'
                }
            },
            through: 'job'
        },
        user_Asset_Company_Contact: {
            target: 'company',
            join:   {
                from:    'user.contactId',
                to:      'company.companyId',
                through: {
                    from: 'asset.contactId',
                    to:   'asset.companyId'
                }
            },
            through: 'asset'
        },
        user_Asset_Site_Contact: {
            target: 'site',
            join:   {
                from:    'user.contactId',
                to:      'site.siteId',
                through: {
                    from: 'asset.contactId',
                    to:   'asset.siteId'
                }
            },
            through: 'asset'
        },
        user_Asset_Manufacturer_Contact: {
            target: 'manufacturer',
            join:   {
                from:    'user.contactId',
                to:      'manufacturer.manufacturerId',
                through: {
                    from: 'asset.contactId',
                    to:   'asset.manufacturerId'
                }
            },
            through: 'asset'
        },
        user_Asset_Sla_Contact: {
            target: 'sla',
            join:   {
                from:    'user.contactId',
                to:      'sla.slaId',
                through: {
                    from: 'asset.contactId',
                    to:   'asset.slaId'
                }
            },
            through: 'asset'
        },
        user_Asset_AssetType_Contact: {
            target: 'assetType',
            join:   {
                from:    'user.contactId',
                to:      'assetType.assetTypeId',
                through: {
                    from: 'asset.contactId',
                    to:   'asset.assetTypeId'
                }
            },
            through: 'asset'
        },
        user_Asset_AssetStatus_Contact: {
            target: 'assetStatus',
            join:   {
                from:    'user.contactId',
                to:      'assetStatus.assetStatusId',
                through: {
                    from: 'asset.contactId',
                    to:   'asset.assetStatusId'
                }
            },
            through: 'asset'
        },
        user_CompanyUser_Company_User: {
            target: 'company',
            join:   {
                from:    'user.userId',
                to:      'company.companyId',
                through: {
                    from: 'companyUser.userId',
                    to:   'companyUser.companyId'
                }
            },
            through: 'companyUser'
        },
        user_Company_LicoriceName_Contact: {
            target: 'licoriceName',
            join:   {
                from:    'user.contactId',
                to:      'licoriceName.companyStatus',
                through: {
                    from: 'company.contactId',
                    to:   'company.companyStatus'
                }
            },
            through: 'company'
        },
        user_Appointment_User_Creator: {
            target: 'user',
            join:   {
                from:    'user.creatorId',
                to:      'user.userId',
                through: {
                    from: 'appointment.creatorId',
                    to:   'appointment.userId'
                }
            },
            through: 'appointment'
        },
        user_Appointment_User_User: {
            target: 'user',
            join:   {
                from:    'user.userId',
                to:      'user.creatorId',
                through: {
                    from: 'appointment.userId',
                    to:   'appointment.creatorId'
                }
            },
            through: 'appointment'
        },
        user_Appointment_User_Creator1: {
            target: 'user',
            join:   {
                from:    'user.creatorId',
                to:      'user.previousUserId',
                through: {
                    from: 'appointment.creatorId',
                    to:   'appointment.previousUserId'
                }
            },
            through: 'appointment'
        },
        user_Appointment_User_PreviousUser: {
            target: 'user',
            join:   {
                from:    'user.previousUserId',
                to:      'user.creatorId',
                through: {
                    from: 'appointment.previousUserId',
                    to:   'appointment.creatorId'
                }
            },
            through: 'appointment'
        },
        user_Appointment_Job_Creator: {
            target: 'job',
            join:   {
                from:    'user.creatorId',
                to:      'job.jobId',
                through: {
                    from: 'appointment.creatorId',
                    to:   'appointment.jobId'
                }
            },
            through: 'appointment'
        },
        user_Appointment_SchedulerEvent_Creator: {
            target: 'schedulerEvent',
            join:   {
                from:    'user.creatorId',
                to:      'schedulerEvent.generator',
                through: {
                    from: 'appointment.creatorId',
                    to:   'appointment.generator'
                }
            },
            through: 'appointment'
        },
        user_Appointment_User_User1: {
            target: 'user',
            join:   {
                from:    'user.userId',
                to:      'user.previousUserId',
                through: {
                    from: 'appointment.userId',
                    to:   'appointment.previousUserId'
                }
            },
            through: 'appointment'
        },
        user_Appointment_User_PreviousUser1: {
            target: 'user',
            join:   {
                from:    'user.previousUserId',
                to:      'user.userId',
                through: {
                    from: 'appointment.previousUserId',
                    to:   'appointment.userId'
                }
            },
            through: 'appointment'
        },
        user_Appointment_Job_User: {
            target: 'job',
            join:   {
                from:    'user.userId',
                to:      'job.jobId',
                through: {
                    from: 'appointment.userId',
                    to:   'appointment.jobId'
                }
            },
            through: 'appointment'
        },
        user_Appointment_SchedulerEvent_User: {
            target: 'schedulerEvent',
            join:   {
                from:    'user.userId',
                to:      'schedulerEvent.generator',
                through: {
                    from: 'appointment.userId',
                    to:   'appointment.generator'
                }
            },
            through: 'appointment'
        },
        user_Appointment_Job_PreviousUser: {
            target: 'job',
            join:   {
                from:    'user.previousUserId',
                to:      'job.jobId',
                through: {
                    from: 'appointment.previousUserId',
                    to:   'appointment.jobId'
                }
            },
            through: 'appointment'
        },
        user_Appointment_SchedulerEvent_PreviousUser: {
            target: 'schedulerEvent',
            join:   {
                from:    'user.previousUserId',
                to:      'schedulerEvent.generator',
                through: {
                    from: 'appointment.previousUserId',
                    to:   'appointment.generator'
                }
            },
            through: 'appointment'
        },
        user_CalendarEvent_LicoriceName_User: {
            target: 'licoriceName',
            join:   {
                from:    'user.userId',
                to:      'licoriceName.licoriceNameId',
                through: {
                    from: 'calendarEvent.userId',
                    to:   'calendarEvent.licoriceNameId'
                }
            },
            through: 'calendarEvent'
        },
        user_CalendarEvent_SchedulerEvent_User: {
            target: 'schedulerEvent',
            join:   {
                from:    'user.userId',
                to:      'schedulerEvent.generator',
                through: {
                    from: 'calendarEvent.userId',
                    to:   'calendarEvent.generator'
                }
            },
            through: 'calendarEvent'
        },
        user_TimeLog_LicoriceName_User: {
            target: 'licoriceName',
            join:   {
                from:    'user.userId',
                to:      'licoriceName.billingTypeId',
                through: {
                    from: 'timeLog.userId',
                    to:   'timeLog.billingTypeId'
                }
            },
            through: 'timeLog'
        },
        user_TimeLog_Job_User: {
            target: 'job',
            join:   {
                from:    'user.userId',
                to:      'job.jobId',
                through: {
                    from: 'timeLog.userId',
                    to:   'timeLog.jobId'
                }
            },
            through: 'timeLog'
        },
        user_Checklist_Job_User: {
            target: 'job',
            join:   {
                from:    'user.userId',
                to:      'job.jobId',
                through: {
                    from: 'checklist.userId',
                    to:   'checklist.jobId'
                }
            },
            through: 'checklist'
        },
        user_Note_Job_User: {
            target: 'job',
            join:   {
                from:    'user.userId',
                to:      'job.jobId',
                through: {
                    from: 'note.userId',
                    to:   'note.jobId'
                }
            },
            through: 'note'
        },
        user_Note_TimeLog_User: {
            target: 'timeLog',
            join:   {
                from:    'user.userId',
                to:      'timeLog.timeLogId',
                through: {
                    from: 'note.userId',
                    to:   'note.timeLogId'
                }
            },
            through: 'note'
        },
        user_TagUser_Tag_User: {
            target: 'tag',
            join:   {
                from:    'user.userId',
                to:      'tag.tagId',
                through: {
                    from: 'tagUser.userId',
                    to:   'tagUser.tagId'
                }
            },
            through: 'tagUser'
        },
        user_UserJob_Job_User: {
            target: 'job',
            join:   {
                from:    'user.userId',
                to:      'job.jobId',
                through: {
                    from: 'userJob.userId',
                    to:   'userJob.jobId'
                }
            },
            through: 'userJob'
        },
        user_UserAppointment_Appointment_User: {
            target: 'appointment',
            join:   {
                from:    'user.userId',
                to:      'appointment.appointmentId',
                through: {
                    from: 'userAppointment.userId',
                    to:   'userAppointment.appointmentId'
                }
            },
            through: 'userAppointment'
        },
        user_NotificationUnviewed_Notification_User: {
            target: 'notification',
            join:   {
                from:    'user.userId',
                to:      'notification.notificationId',
                through: {
                    from: 'notificationUnviewed.userId',
                    to:   'notificationUnviewed.notificationId'
                }
            },
            through: 'notificationUnviewed'
        },
        user_UserSite_Site_User: {
            target: 'site',
            join:   {
                from:    'user.userId',
                to:      'site.siteId',
                through: {
                    from: 'userSite.userId',
                    to:   'userSite.siteId'
                }
            },
            through: 'userSite'
        }
    };
    validations = {
        name: rxAny,
        role: rxUserRole
    };
    copy = [
        {
            from: 'id',
            to:   'providerUserId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const user = new UserRelation();

class LicoriceNameRelation extends RelationHelpers {
    tableName = 'licoriceName';
    modelName = 'LicoriceName';
    correlate = null;
    notify = false;
    idColumn = 'licoriceNameId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'LicoriceNameServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [];
    fields = [
        'licoriceNameId',
        'name',
        'description',
        'data',
        'typeName',
        'sequence',
        'unmapped',
        'category',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'data' ];
    relations = {
        licoriceName_Company: {
            target: 'company',
            join:   {
                from: 'licoriceName.companyStatus',
                to:   'company.companyStatus'
            }
        },
        licoriceName_CompanyStatusNote: {
            target: 'companyStatusNote',
            join:   {
                from: 'licoriceName.statusId',
                to:   'companyStatusNote.statusId'
            }
        },
        licoriceName_Job: {
            target: 'job',
            join:   {
                from: 'licoriceName.statusId',
                to:   'job.statusId'
            }
        },
        licoriceName_CalendarEvent: {
            target: 'calendarEvent',
            join:   {
                from: 'licoriceName.licoriceNameId',
                to:   'calendarEvent.licoriceNameId'
            }
        },
        licoriceName_TimeLog: {
            target: 'timeLog',
            join:   {
                from: 'licoriceName.billingTypeId',
                to:   'timeLog.billingTypeId'
            }
        },
        licoriceName_Mapping: {
            target: 'mapping',
            join:   {
                from: 'licoriceName.licoriceNameId',
                to:   'mapping.licoriceNameId'
            }
        },
        licoriceName_CompanyStatusNote_Company_Status: {
            target: 'company',
            join:   {
                from:    'licoriceName.statusId',
                to:      'company.companyId',
                through: {
                    from: 'companyStatusNote.statusId',
                    to:   'companyStatusNote.companyId'
                }
            },
            through: 'companyStatusNote'
        },
        licoriceName_Job_Company_Status: {
            target: 'company',
            join:   {
                from:    'licoriceName.statusId',
                to:      'company.companyId',
                through: {
                    from: 'job.statusId',
                    to:   'job.companyId'
                }
            },
            through: 'job'
        },
        licoriceName_Job_User_Status: {
            target: 'user',
            join:   {
                from:    'licoriceName.statusId',
                to:      'user.userId',
                through: {
                    from: 'job.statusId',
                    to:   'job.userId'
                }
            },
            through: 'job'
        },
        licoriceName_Job_User_Status1: {
            target: 'user',
            join:   {
                from:    'licoriceName.statusId',
                to:      'user.creatorId',
                through: {
                    from: 'job.statusId',
                    to:   'job.creatorId'
                }
            },
            through: 'job'
        },
        licoriceName_Job_User_Status2: {
            target: 'user',
            join:   {
                from:    'licoriceName.statusId',
                to:      'user.contactId',
                through: {
                    from: 'job.statusId',
                    to:   'job.contactId'
                }
            },
            through: 'job'
        },
        licoriceName_Company_User_CompanyStatus: {
            target: 'user',
            join:   {
                from:    'licoriceName.companyStatus',
                to:      'user.contactId',
                through: {
                    from: 'company.companyStatus',
                    to:   'company.contactId'
                }
            },
            through: 'company'
        },
        licoriceName_CalendarEvent_User_LicoriceName: {
            target: 'user',
            join:   {
                from:    'licoriceName.licoriceNameId',
                to:      'user.userId',
                through: {
                    from: 'calendarEvent.licoriceNameId',
                    to:   'calendarEvent.userId'
                }
            },
            through: 'calendarEvent'
        },
        licoriceName_CalendarEvent_SchedulerEvent_LicoriceName: {
            target: 'schedulerEvent',
            join:   {
                from:    'licoriceName.licoriceNameId',
                to:      'schedulerEvent.generator',
                through: {
                    from: 'calendarEvent.licoriceNameId',
                    to:   'calendarEvent.generator'
                }
            },
            through: 'calendarEvent'
        },
        licoriceName_TimeLog_User_BillingType: {
            target: 'user',
            join:   {
                from:    'licoriceName.billingTypeId',
                to:      'user.userId',
                through: {
                    from: 'timeLog.billingTypeId',
                    to:   'timeLog.userId'
                }
            },
            through: 'timeLog'
        },
        licoriceName_TimeLog_Job_BillingType: {
            target: 'job',
            join:   {
                from:    'licoriceName.billingTypeId',
                to:      'job.jobId',
                through: {
                    from: 'timeLog.billingTypeId',
                    to:   'timeLog.jobId'
                }
            },
            through: 'timeLog'
        },
        licoriceName_Mapping_ProviderName_LicoriceName: {
            target: 'providerName',
            join:   {
                from:    'licoriceName.licoriceNameId',
                to:      'providerName.providerNameId',
                through: {
                    from: 'mapping.licoriceNameId',
                    to:   'mapping.providerNameId'
                }
            },
            through: 'mapping'
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const licoriceName = new LicoriceNameRelation();

class CompanyStatusNoteRelation extends RelationHelpers {
    tableName = 'companyStatusNote';
    modelName = 'CompanyStatusNote';
    correlate = null;
    notify = false;
    idColumn = 'companyStatusNoteId';
    providerIdColumn = 'providerCompanyStatusNoteId';
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'CompanyStatusNoteServices';
    converter = 'companyStatusNote';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'companyId',
        'statusId'
    ];
    fields = [
        'companyStatusNoteId',
        'providerCompanyStatusNoteId',
        'companyId',
        'statusId',
        'note',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        companyStatusNote_Company: {
            target: 'company',
            join:   {
                from: 'companyStatusNote.companyId',
                to:   'company.companyId'
            }
        },
        companyStatusNote_LicoriceName: {
            target: 'licoriceName',
            join:   {
                from: 'companyStatusNote.statusId',
                to:   'licoriceName.statusId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = [ 'note' ];
    conflictFields = [
        'companyId',
        'statusId'
    ];
}

export const companyStatusNote = new CompanyStatusNoteRelation();

class JobRelation extends RelationHelpers {
    tableName = 'job';
    modelName = 'Job';
    correlate = 'jobs';
    notify = true;
    idColumn = 'jobId';
    providerIdColumn = 'providerJobId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [ 'completedDate' ];
    controllerName = 'JobServices';
    converter = 'job';
    ignore = [
        'creatorId',
        'contactId',
        'type',
        'description',
        'completedDate',
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [ 'statusId' ];
    fields = [
        'jobId',
        'billable',
        'providerJobId',
        'providerBoardId',
        'providerStatusId',
        'providerContactId',
        'previousProviderStatusId',
        'companyId',
        'userId',
        'creatorId',
        'contactId',
        'priority',
        'type',
        'description',
        'title',
        'version',
        'statusId',
        'completedDate',
        'users',
        'estimatedTime',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'version' ];
    relations = {
        job_Company: {
            target: 'company',
            join:   {
                from: 'job.companyId',
                to:   'company.companyId'
            }
        },
        job_User1: {
            target: 'user',
            join:   {
                from: 'job.userId',
                to:   'user.userId'
            }
        },
        job_User3: {
            target: 'user',
            join:   {
                from: 'job.creatorId',
                to:   'user.creatorId'
            }
        },
        job_User: {
            target: 'user',
            join:   {
                from: 'job.contactId',
                to:   'user.contactId'
            }
        },
        job_LicoriceName: {
            target: 'licoriceName',
            join:   {
                from: 'job.statusId',
                to:   'licoriceName.statusId'
            }
        },
        job_Appointment: {
            target: 'appointment',
            join:   {
                from: 'job.jobId',
                to:   'appointment.jobId'
            }
        },
        job_TimeLog: {
            target: 'timeLog',
            join:   {
                from: 'job.jobId',
                to:   'timeLog.jobId'
            }
        },
        job_Checklist: {
            target: 'checklist',
            join:   {
                from: 'job.jobId',
                to:   'checklist.jobId'
            }
        },
        job_Note: {
            target: 'note',
            join:   {
                from: 'job.jobId',
                to:   'note.jobId'
            }
        },
        job_JobAsset: {
            target: 'jobAsset',
            join:   {
                from: 'job.jobId',
                to:   'jobAsset.jobId'
            }
        },
        job_TagJob: {
            target: 'tagJob',
            join:   {
                from: 'job.jobId',
                to:   'tagJob.jobId'
            }
        },
        job_UserJob: {
            target: 'userJob',
            join:   {
                from: 'job.jobId',
                to:   'userJob.jobId'
            }
        },
        job_Appointment_User_Job: {
            target: 'user',
            join:   {
                from:    'job.jobId',
                to:      'user.creatorId',
                through: {
                    from: 'appointment.jobId',
                    to:   'appointment.creatorId'
                }
            },
            through: 'appointment'
        },
        job_Appointment_User_Job1: {
            target: 'user',
            join:   {
                from:    'job.jobId',
                to:      'user.userId',
                through: {
                    from: 'appointment.jobId',
                    to:   'appointment.userId'
                }
            },
            through: 'appointment'
        },
        job_Appointment_User_Job2: {
            target: 'user',
            join:   {
                from:    'job.jobId',
                to:      'user.previousUserId',
                through: {
                    from: 'appointment.jobId',
                    to:   'appointment.previousUserId'
                }
            },
            through: 'appointment'
        },
        job_Appointment_SchedulerEvent_Job: {
            target: 'schedulerEvent',
            join:   {
                from:    'job.jobId',
                to:      'schedulerEvent.generator',
                through: {
                    from: 'appointment.jobId',
                    to:   'appointment.generator'
                }
            },
            through: 'appointment'
        },
        job_TimeLog_User_Job: {
            target: 'user',
            join:   {
                from:    'job.jobId',
                to:      'user.userId',
                through: {
                    from: 'timeLog.jobId',
                    to:   'timeLog.userId'
                }
            },
            through: 'timeLog'
        },
        job_TimeLog_LicoriceName_Job: {
            target: 'licoriceName',
            join:   {
                from:    'job.jobId',
                to:      'licoriceName.billingTypeId',
                through: {
                    from: 'timeLog.jobId',
                    to:   'timeLog.billingTypeId'
                }
            },
            through: 'timeLog'
        },
        job_Checklist_User_Job: {
            target: 'user',
            join:   {
                from:    'job.jobId',
                to:      'user.userId',
                through: {
                    from: 'checklist.jobId',
                    to:   'checklist.userId'
                }
            },
            through: 'checklist'
        },
        job_Note_User_Job: {
            target: 'user',
            join:   {
                from:    'job.jobId',
                to:      'user.userId',
                through: {
                    from: 'note.jobId',
                    to:   'note.userId'
                }
            },
            through: 'note'
        },
        job_Note_TimeLog_Job: {
            target: 'timeLog',
            join:   {
                from:    'job.jobId',
                to:      'timeLog.timeLogId',
                through: {
                    from: 'note.jobId',
                    to:   'note.timeLogId'
                }
            },
            through: 'note'
        },
        job_UserJob_User_Job: {
            target: 'user',
            join:   {
                from:    'job.jobId',
                to:      'user.userId',
                through: {
                    from: 'userJob.jobId',
                    to:   'userJob.userId'
                }
            },
            through: 'userJob'
        },
        job_JobAsset_Asset_Job: {
            target: 'asset',
            join:   {
                from:    'job.jobId',
                to:      'asset.assetId',
                through: {
                    from: 'jobAsset.jobId',
                    to:   'jobAsset.assetId'
                }
            },
            through: 'jobAsset'
        },
        job_TagJob_Tag_Job: {
            target: 'tag',
            join:   {
                from:    'job.jobId',
                to:      'tag.tagId',
                through: {
                    from: 'tagJob.jobId',
                    to:   'tagJob.tagId'
                }
            },
            through: 'tagJob'
        }
    };
    validations = {
        companyId: rxUUID,
        title:     rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerJobId'
        },
        {
            from: 'board.id',
            to:   'providerBoardId'
        },
        {
            from: 'status.id',
            to:   'providerStatusId'
        },
        {
            from: 'contact.id',
            to:   'providerContactId'
        },
        {
            from: '.providerStatusId',
            to:   'previousProviderStatusId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
    deps = {
        appointment: 'jobId',
        note:        'jobId',
        userJob:     'jobId'
    };
    dependents = [
        'appointment',
        'note',
        'userJob'
    ];
}

export const job = new JobRelation();

class AppointmentRelation extends RelationHelpers {
    tableName = 'appointment';
    modelName = 'Appointment';
    correlate = 'schedules';
    notify = false;
    idColumn = 'appointmentId';
    providerIdColumn = 'providerAppointmentId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [
        'startDate',
        'endDate'
    ];
    controllerName = 'AppointmentServices';
    converter = 'appointment';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [ 'jobId' ];
    fields = [
        'appointmentId',
        'providerAppointmentId',
        'creatorId',
        'userId',
        'previousUserId',
        'jobId',
        'startDate',
        'endDate',
        'completedDate',
        'state',
        'version',
        'sequence',
        'createdOn',
        'updatedOn',
        'generator'
    ];
    jsonFields = [ 'version' ];
    relations = {
        appointment_User1: {
            target: 'user',
            join:   {
                from: 'appointment.creatorId',
                to:   'user.creatorId'
            }
        },
        appointment_User3: {
            target: 'user',
            join:   {
                from: 'appointment.userId',
                to:   'user.userId'
            }
        },
        appointment_User: {
            target: 'user',
            join:   {
                from: 'appointment.previousUserId',
                to:   'user.previousUserId'
            }
        },
        appointment_Job: {
            target: 'job',
            join:   {
                from: 'appointment.jobId',
                to:   'job.jobId'
            }
        },
        appointment_SchedulerEvent: {
            target: 'schedulerEvent',
            join:   {
                from: 'appointment.appointmentId',
                to:   'schedulerEvent.appointmentId'
            }
        },
        appointment_UserAppointment: {
            target: 'userAppointment',
            join:   {
                from: 'appointment.appointmentId',
                to:   'userAppointment.appointmentId'
            }
        },
        appointment_SchedulerEventGenerator: {
            target: 'schedulerEvent',
            join:   {
                from: 'appointment.generator',
                to:   'schedulerEvent.generator'
            }
        },
        appointment_UserAppointment_User_Appointment: {
            target: 'user',
            join:   {
                from:    'appointment.appointmentId',
                to:      'user.userId',
                through: {
                    from: 'userAppointment.appointmentId',
                    to:   'userAppointment.userId'
                }
            },
            through: 'userAppointment'
        }
    };
    validations = {
        jobId: rxUUID
    };
    copy = [
        {
            from: 'id',
            to:   'providerAppointmentId'
        }
    ];
    parent = null;
    waitsFor = {
        job: 'jobId'
    };
    conflict = undefined;
    conflictFields = undefined;
}

export const appointment = new AppointmentRelation();

class CalendarEventRelation extends RelationHelpers {
    tableName = 'calendarEvent';
    modelName = 'CalendarEvent';
    correlate = 'schedules';
    notify = true;
    idColumn = 'calendarEventId';
    providerIdColumn = 'providerCalendarEventId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [
        'startDate',
        'endDate'
    ];
    controllerName = 'CalendarEventServices';
    converter = 'calendarEvent';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'userId',
        'licoriceNameId'
    ];
    fields = [
        'calendarEventId',
        'providerCalendarEventId',
        'userId',
        'licoriceNameId',
        'title',
        'description',
        'startDate',
        'endDate',
        'isPrivate',
        'version',
        'sequence',
        'createdOn',
        'updatedOn',
        'generator'
    ];
    jsonFields = [ 'version' ];
    relations = {
        calendarEvent_User: {
            target: 'user',
            join:   {
                from: 'calendarEvent.userId',
                to:   'user.userId'
            }
        },
        calendarEvent_LicoriceName: {
            target: 'licoriceName',
            join:   {
                from: 'calendarEvent.licoriceNameId',
                to:   'licoriceName.licoriceNameId'
            }
        },
        calendarEvent_SchedulerEvent: {
            target: 'schedulerEvent',
            join:   {
                from: 'calendarEvent.calendarEventId',
                to:   'schedulerEvent.calendarEventId'
            }
        },
        calendarEvent_SchedulerEventGenerator: {
            target: 'schedulerEvent',
            join:   {
                from: 'calendarEvent.generator',
                to:   'schedulerEvent.generator'
            }
        }
    };
    validations = {
        userId: rxUUID
    };
    copy = [
        {
            from: 'id',
            to:   'providerCalendarEventId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const calendarEvent = new CalendarEventRelation();

class SchedulerEventRelation extends RelationHelpers {
    tableName = 'schedulerEvent';
    modelName = 'SchedulerEvent';
    correlate = null;
    notify = false;
    idColumn = 'schedulerEventId';
    providerIdColumn = 'providerSchedulerEventId';
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [ 'endsOn' ];
    controllerName = 'SchedulerEventServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [];
    fields = [
        'schedulerEventId',
        'providerSchedulerEventId',
        'recurring',
        'appointmentId',
        'calendarEventId',
        'weekdays',
        'interval',
        'weekOfMonth',
        'endsOn',
        'endsAfter',
        'lastEventGenerated',
        'lastSequenceGenerated',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        schedulerEvent_Appointment: {
            target: 'appointment',
            join:   {
                from: 'schedulerEvent.appointmentId',
                to:   'appointment.appointmentId'
            }
        },
        schedulerEvent_CalendarEvent: {
            target: 'calendarEvent',
            join:   {
                from: 'schedulerEvent.calendarEventId',
                to:   'calendarEvent.calendarEventId'
            }
        },
        schedulerEvent_AppointmentGenerator: {
            target: 'appointment',
            join:   {
                from: 'schedulerEvent.generator',
                to:   'appointment.generator'
            }
        },
        schedulerEvent_CalendarEventGenerator: {
            target: 'calendarEvent',
            join:   {
                from: 'schedulerEvent.generator',
                to:   'calendarEvent.generator'
            }
        },
        schedulerEvent_Appointment_User_Generator: {
            target: 'user',
            join:   {
                from:    'schedulerEvent.generator',
                to:      'user.creatorId',
                through: {
                    from: 'appointment.generator',
                    to:   'appointment.creatorId'
                }
            },
            through: 'appointment'
        },
        schedulerEvent_Appointment_User_Generator1: {
            target: 'user',
            join:   {
                from:    'schedulerEvent.generator',
                to:      'user.userId',
                through: {
                    from: 'appointment.generator',
                    to:   'appointment.userId'
                }
            },
            through: 'appointment'
        },
        schedulerEvent_Appointment_User_Generator2: {
            target: 'user',
            join:   {
                from:    'schedulerEvent.generator',
                to:      'user.previousUserId',
                through: {
                    from: 'appointment.generator',
                    to:   'appointment.previousUserId'
                }
            },
            through: 'appointment'
        },
        schedulerEvent_Appointment_Job_Generator: {
            target: 'job',
            join:   {
                from:    'schedulerEvent.generator',
                to:      'job.jobId',
                through: {
                    from: 'appointment.generator',
                    to:   'appointment.jobId'
                }
            },
            through: 'appointment'
        },
        schedulerEvent_CalendarEvent_User_Generator: {
            target: 'user',
            join:   {
                from:    'schedulerEvent.generator',
                to:      'user.userId',
                through: {
                    from: 'calendarEvent.generator',
                    to:   'calendarEvent.userId'
                }
            },
            through: 'calendarEvent'
        },
        schedulerEvent_CalendarEvent_LicoriceName_Generator: {
            target: 'licoriceName',
            join:   {
                from:    'schedulerEvent.generator',
                to:      'licoriceName.licoriceNameId',
                through: {
                    from: 'calendarEvent.generator',
                    to:   'calendarEvent.licoriceNameId'
                }
            },
            through: 'calendarEvent'
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const schedulerEvent = new SchedulerEventRelation();

class TokenRelation extends RelationHelpers {
    tableName = 'token';
    modelName = 'Token';
    correlate = null;
    notify = false;
    idColumn = 'tokenId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [ 'expires' ];
    controllerName = 'TokenServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'recipientId',
        'purpose',
        'tokenData',
        'expires'
    ];
    fields = [
        'tokenId',
        'userId',
        'recipientId',
        'purpose',
        'status',
        'tokenData',
        'expires',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        token_User1: {
            target: 'user',
            join:   {
                from: 'token.userId',
                to:   'user.userId'
            }
        },
        token_User: {
            target: 'user',
            join:   {
                from: 'token.recipientId',
                to:   'user.recipientId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const token = new TokenRelation();

class NotificationRelation extends RelationHelpers {
    tableName = 'notification';
    modelName = 'Notification';
    correlate = null;
    notify = false;
    idColumn = 'notificationId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [ 'expires' ];
    controllerName = 'NotificationServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [ 'namespace' ];
    fields = [
        'notificationId',
        'userId',
        'status',
        'data',
        'type',
        'dismissable',
        'expires',
        'namespace',
        'distributionTargets',
        'responses',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [
        'data',
        'distributionTargets',
        'responses'
    ];
    relations = {
        notification_User: {
            target: 'user',
            join:   {
                from: 'notification.userId',
                to:   'user.userId'
            }
        },
        notification_NotificationUnviewed: {
            target: 'notificationUnviewed',
            join:   {
                from: 'notification.notificationId',
                to:   'notificationUnviewed.notificationId'
            }
        },
        notification_NotificationUnviewed_User_Notification: {
            target: 'user',
            join:   {
                from:    'notification.notificationId',
                to:      'user.userId',
                through: {
                    from: 'notificationUnviewed.notificationId',
                    to:   'notificationUnviewed.userId'
                }
            },
            through: 'notificationUnviewed'
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const notification = new NotificationRelation();

class TimeLogRelation extends RelationHelpers {
    tableName = 'timeLog';
    modelName = 'TimeLog';
    correlate = 'timeEntries';
    notify = false;
    idColumn = 'timeLogId';
    providerIdColumn = 'providerTimeLogId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [
        'start',
        'finish'
    ];
    controllerName = 'TimeLogServices';
    converter = 'timeLog';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'jobId',
        'userId',
        'start',
        'billingTypeId'
    ];
    fields = [
        'timeLogId',
        'jobId',
        'userId',
        'start',
        'finish',
        'editedAt',
        'pausedAt',
        'cumulativePause',
        'duration',
        'pegboard',
        'billable',
        'billingTypeId',
        'version',
        'providerTimeLogId',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'version' ];
    relations = {
        timeLog_User: {
            target: 'user',
            join:   {
                from: 'timeLog.userId',
                to:   'user.userId'
            }
        },
        timeLog_LicoriceName: {
            target: 'licoriceName',
            join:   {
                from: 'timeLog.billingTypeId',
                to:   'licoriceName.billingTypeId'
            }
        },
        timeLog_Job: {
            target: 'job',
            join:   {
                from: 'timeLog.jobId',
                to:   'job.jobId'
            }
        },
        timeLog_Note: {
            target: 'note',
            join:   {
                from: 'timeLog.timeLogId',
                to:   'note.timeLogId'
            }
        },
        timeLog_Note_User_TimeLog: {
            target: 'user',
            join:   {
                from:    'timeLog.timeLogId',
                to:      'user.userId',
                through: {
                    from: 'note.timeLogId',
                    to:   'note.userId'
                }
            },
            through: 'note'
        },
        timeLog_Note_Job_TimeLog: {
            target: 'job',
            join:   {
                from:    'timeLog.timeLogId',
                to:      'job.jobId',
                through: {
                    from: 'note.timeLogId',
                    to:   'note.jobId'
                }
            },
            through: 'note'
        }
    };
    validations = {
        jobId:  rxUUID,
        userId: rxUUID
    };
    copy = [
        {
            from: 'id',
            to:   'providerTimeLogId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const timeLog = new TimeLogRelation();

class ChecklistRelation extends RelationHelpers {
    tableName = 'checklist';
    modelName = 'Checklist';
    correlate = 'checklists';
    notify = false;
    idColumn = 'checklistId';
    providerIdColumn = 'providerChecklistId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'ChecklistServices';
    converter = 'checklist';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [];
    fields = [
        'checklistId',
        'jobId',
        'userId',
        'sequence',
        'providerChecklistId',
        'name',
        'status',
        'version',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'version' ];
    relations = {
        checklist_User: {
            target: 'user',
            join:   {
                from: 'checklist.userId',
                to:   'user.userId'
            }
        },
        checklist_Job: {
            target: 'job',
            join:   {
                from: 'checklist.jobId',
                to:   'job.jobId'
            }
        }
    };
    validations = {
        name:   rxAny,
        status: rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerChecklistId'
        }
    ];
    parent = {
        connectwise: {
            provider: 'ticketId',
            licorice: 'jobId'
        }
    };
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const checklist = new ChecklistRelation();

class EmailRelation extends RelationHelpers {
    tableName = 'email';
    modelName = 'Email';
    correlate = null;
    notify = false;
    idColumn = 'emailId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'EmailServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'sentFrom',
        'sentTo'
    ];
    fields = [
        'emailId',
        'sentFrom',
        'sentTo',
        'error',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {};
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const email = new EmailRelation();

class NoteRelation extends RelationHelpers {
    tableName = 'note';
    modelName = 'Note';
    correlate = 'notes';
    notify = false;
    idColumn = 'noteId';
    providerIdColumn = 'providerNoteId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'NoteServices';
    converter = 'note';
    ignore = [
        'recipients',
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'userId',
        'jobId'
    ];
    fields = [
        'noteId',
        'providerNoteId',
        'userId',
        'jobId',
        'noteType',
        'content',
        'recipients',
        'version',
        'duration',
        'timeLogId',
        'flags',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [
        'recipients',
        'version'
    ];
    relations = {
        note_User: {
            target: 'user',
            join:   {
                from: 'note.userId',
                to:   'user.userId'
            }
        },
        note_Job: {
            target: 'job',
            join:   {
                from: 'note.jobId',
                to:   'job.jobId'
            }
        },
        note_TimeLog: {
            target: 'timeLog',
            join:   {
                from: 'note.timeLogId',
                to:   'timeLog.timeLogId'
            }
        },
        note_NoteAttachment: {
            target: 'noteAttachment',
            join:   {
                from: 'note.noteId',
                to:   'noteAttachment.noteId'
            }
        }
    };
    validations = {
        content: rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerNoteId'
        }
    ];
    parent = {
        connectwise: {
            provider: 'ticketId',
            licorice: 'jobId'
        }
    };
    waitsFor = {
        job: 'jobId'
    };
    conflict = undefined;
    conflictFields = undefined;
}

export const note = new NoteRelation();

class NoteAttachmentRelation extends RelationHelpers {
    tableName = 'noteAttachment';
    modelName = 'NoteAttachment';
    correlate = null;
    notify = false;
    idColumn = 'noteAttachmentId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'NoteAttachmentServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'url',
        'size',
        'mimeType'
    ];
    fields = [
        'noteAttachmentId',
        'noteId',
        'name',
        'url',
        'size',
        'mimeType',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        noteAttachment_Note: {
            target: 'note',
            join:   {
                from: 'noteAttachment.noteId',
                to:   'note.noteId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const noteAttachment = new NoteAttachmentRelation();

class ConfigurationRelation extends RelationHelpers {
    tableName = 'configuration';
    modelName = 'Configuration';
    correlate = null;
    notify = false;
    idColumn = 'configurationId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'ConfigurationServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [];
    fields = [
        'configurationId',
        'type',
        'data',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'data' ];
    relations = {};
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = [];
    conflictFields = [ 'type' ];
}

export const configuration = new ConfigurationRelation();

class SiteRelation extends RelationHelpers {
    tableName = 'site';
    modelName = 'Site';
    correlate = 'sites';
    notify = false;
    idColumn = 'siteId';
    providerIdColumn = 'providerSiteId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'SiteServices';
    converter = 'site';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [];
    fields = [
        'siteId',
        'providerSiteId',
        'name',
        'addressLine1',
        'addressLine2',
        'city',
        'state',
        'postalCode',
        'country',
        'coms',
        'version',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [
        'coms',
        'version'
    ];
    relations = {
        site_Asset: {
            target: 'asset',
            join:   {
                from: 'site.siteId',
                to:   'asset.siteId'
            }
        },
        site_CompanySite: {
            target: 'companySite',
            join:   {
                from: 'site.siteId',
                to:   'companySite.siteId'
            }
        },
        site_UserSite: {
            target: 'userSite',
            join:   {
                from: 'site.siteId',
                to:   'userSite.siteId'
            }
        },
        site_Asset_Company_Site: {
            target: 'company',
            join:   {
                from:    'site.siteId',
                to:      'company.companyId',
                through: {
                    from: 'asset.siteId',
                    to:   'asset.companyId'
                }
            },
            through: 'asset'
        },
        site_Asset_User_Site: {
            target: 'user',
            join:   {
                from:    'site.siteId',
                to:      'user.contactId',
                through: {
                    from: 'asset.siteId',
                    to:   'asset.contactId'
                }
            },
            through: 'asset'
        },
        site_Asset_Manufacturer_Site: {
            target: 'manufacturer',
            join:   {
                from:    'site.siteId',
                to:      'manufacturer.manufacturerId',
                through: {
                    from: 'asset.siteId',
                    to:   'asset.manufacturerId'
                }
            },
            through: 'asset'
        },
        site_Asset_Sla_Site: {
            target: 'sla',
            join:   {
                from:    'site.siteId',
                to:      'sla.slaId',
                through: {
                    from: 'asset.siteId',
                    to:   'asset.slaId'
                }
            },
            through: 'asset'
        },
        site_Asset_AssetType_Site: {
            target: 'assetType',
            join:   {
                from:    'site.siteId',
                to:      'assetType.assetTypeId',
                through: {
                    from: 'asset.siteId',
                    to:   'asset.assetTypeId'
                }
            },
            through: 'asset'
        },
        site_Asset_AssetStatus_Site: {
            target: 'assetStatus',
            join:   {
                from:    'site.siteId',
                to:      'assetStatus.assetStatusId',
                through: {
                    from: 'asset.siteId',
                    to:   'asset.assetStatusId'
                }
            },
            through: 'asset'
        },
        site_CompanySite_Company_Site: {
            target: 'company',
            join:   {
                from:    'site.siteId',
                to:      'company.companyId',
                through: {
                    from: 'companySite.siteId',
                    to:   'companySite.companyId'
                }
            },
            through: 'companySite'
        },
        site_UserSite_User_Site: {
            target: 'user',
            join:   {
                from:    'site.siteId',
                to:      'user.userId',
                through: {
                    from: 'userSite.siteId',
                    to:   'userSite.userId'
                }
            },
            through: 'userSite'
        }
    };
    validations = {
        name: rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerSiteId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const site = new SiteRelation();

class ManufacturerRelation extends RelationHelpers {
    tableName = 'manufacturer';
    modelName = 'Manufacturer';
    correlate = 'manufacturers';
    notify = false;
    idColumn = 'manufacturerId';
    providerIdColumn = 'providerManufacturerId';
    links = null;
    sync = 1;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'ManufacturerServices';
    converter = 'manufacturer';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [ 'providerManufacturerId' ];
    fields = [
        'manufacturerId',
        'name',
        'providerManufacturerId',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        manufacturer_Asset: {
            target: 'asset',
            join:   {
                from: 'manufacturer.manufacturerId',
                to:   'asset.manufacturerId'
            }
        },
        manufacturer_Asset_Company_Manufacturer: {
            target: 'company',
            join:   {
                from:    'manufacturer.manufacturerId',
                to:      'company.companyId',
                through: {
                    from: 'asset.manufacturerId',
                    to:   'asset.companyId'
                }
            },
            through: 'asset'
        },
        manufacturer_Asset_User_Manufacturer: {
            target: 'user',
            join:   {
                from:    'manufacturer.manufacturerId',
                to:      'user.contactId',
                through: {
                    from: 'asset.manufacturerId',
                    to:   'asset.contactId'
                }
            },
            through: 'asset'
        },
        manufacturer_Asset_Site_Manufacturer: {
            target: 'site',
            join:   {
                from:    'manufacturer.manufacturerId',
                to:      'site.siteId',
                through: {
                    from: 'asset.manufacturerId',
                    to:   'asset.siteId'
                }
            },
            through: 'asset'
        },
        manufacturer_Asset_Sla_Manufacturer: {
            target: 'sla',
            join:   {
                from:    'manufacturer.manufacturerId',
                to:      'sla.slaId',
                through: {
                    from: 'asset.manufacturerId',
                    to:   'asset.slaId'
                }
            },
            through: 'asset'
        },
        manufacturer_Asset_AssetType_Manufacturer: {
            target: 'assetType',
            join:   {
                from:    'manufacturer.manufacturerId',
                to:      'assetType.assetTypeId',
                through: {
                    from: 'asset.manufacturerId',
                    to:   'asset.assetTypeId'
                }
            },
            through: 'asset'
        },
        manufacturer_Asset_AssetStatus_Manufacturer: {
            target: 'assetStatus',
            join:   {
                from:    'manufacturer.manufacturerId',
                to:      'assetStatus.assetStatusId',
                through: {
                    from: 'asset.manufacturerId',
                    to:   'asset.assetStatusId'
                }
            },
            through: 'asset'
        }
    };
    validations = {
        name: rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerManufacturerId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const manufacturer = new ManufacturerRelation();

class SlaRelation extends RelationHelpers {
    tableName = 'sla';
    modelName = 'Sla';
    correlate = 'slas';
    notify = false;
    idColumn = 'slaId';
    providerIdColumn = 'providerSlaId';
    links = null;
    sync = 1;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'SlaServices';
    converter = 'sla';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [ 'providerSlaId' ];
    fields = [
        'slaId',
        'name',
        'details',
        'providerSlaId',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'details' ];
    relations = {
        sla_Asset: {
            target: 'asset',
            join:   {
                from: 'sla.slaId',
                to:   'asset.slaId'
            }
        },
        sla_Asset_Company_Sla: {
            target: 'company',
            join:   {
                from:    'sla.slaId',
                to:      'company.companyId',
                through: {
                    from: 'asset.slaId',
                    to:   'asset.companyId'
                }
            },
            through: 'asset'
        },
        sla_Asset_User_Sla: {
            target: 'user',
            join:   {
                from:    'sla.slaId',
                to:      'user.contactId',
                through: {
                    from: 'asset.slaId',
                    to:   'asset.contactId'
                }
            },
            through: 'asset'
        },
        sla_Asset_Site_Sla: {
            target: 'site',
            join:   {
                from:    'sla.slaId',
                to:      'site.siteId',
                through: {
                    from: 'asset.slaId',
                    to:   'asset.siteId'
                }
            },
            through: 'asset'
        },
        sla_Asset_Manufacturer_Sla: {
            target: 'manufacturer',
            join:   {
                from:    'sla.slaId',
                to:      'manufacturer.manufacturerId',
                through: {
                    from: 'asset.slaId',
                    to:   'asset.manufacturerId'
                }
            },
            through: 'asset'
        },
        sla_Asset_AssetType_Sla: {
            target: 'assetType',
            join:   {
                from:    'sla.slaId',
                to:      'assetType.assetTypeId',
                through: {
                    from: 'asset.slaId',
                    to:   'asset.assetTypeId'
                }
            },
            through: 'asset'
        },
        sla_Asset_AssetStatus_Sla: {
            target: 'assetStatus',
            join:   {
                from:    'sla.slaId',
                to:      'assetStatus.assetStatusId',
                through: {
                    from: 'asset.slaId',
                    to:   'asset.assetStatusId'
                }
            },
            through: 'asset'
        }
    };
    validations = {
        name: rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerSlaId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const sla = new SlaRelation();

class AssetTypeRelation extends RelationHelpers {
    tableName = 'assetType';
    modelName = 'AssetType';
    correlate = 'assetTypes';
    notify = false;
    idColumn = 'assetTypeId';
    providerIdColumn = 'providerAssetTypeId';
    links = null;
    sync = 1;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'AssetTypeServices';
    converter = 'assetType';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [ 'providerAssetTypeId' ];
    fields = [
        'assetTypeId',
        'name',
        'providerAssetTypeId',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        assetType_Asset: {
            target: 'asset',
            join:   {
                from: 'assetType.assetTypeId',
                to:   'asset.assetTypeId'
            }
        },
        assetType_Asset_Company_AssetType: {
            target: 'company',
            join:   {
                from:    'assetType.assetTypeId',
                to:      'company.companyId',
                through: {
                    from: 'asset.assetTypeId',
                    to:   'asset.companyId'
                }
            },
            through: 'asset'
        },
        assetType_Asset_User_AssetType: {
            target: 'user',
            join:   {
                from:    'assetType.assetTypeId',
                to:      'user.contactId',
                through: {
                    from: 'asset.assetTypeId',
                    to:   'asset.contactId'
                }
            },
            through: 'asset'
        },
        assetType_Asset_Site_AssetType: {
            target: 'site',
            join:   {
                from:    'assetType.assetTypeId',
                to:      'site.siteId',
                through: {
                    from: 'asset.assetTypeId',
                    to:   'asset.siteId'
                }
            },
            through: 'asset'
        },
        assetType_Asset_Manufacturer_AssetType: {
            target: 'manufacturer',
            join:   {
                from:    'assetType.assetTypeId',
                to:      'manufacturer.manufacturerId',
                through: {
                    from: 'asset.assetTypeId',
                    to:   'asset.manufacturerId'
                }
            },
            through: 'asset'
        },
        assetType_Asset_Sla_AssetType: {
            target: 'sla',
            join:   {
                from:    'assetType.assetTypeId',
                to:      'sla.slaId',
                through: {
                    from: 'asset.assetTypeId',
                    to:   'asset.slaId'
                }
            },
            through: 'asset'
        },
        assetType_Asset_AssetStatus_AssetType: {
            target: 'assetStatus',
            join:   {
                from:    'assetType.assetTypeId',
                to:      'assetStatus.assetStatusId',
                through: {
                    from: 'asset.assetTypeId',
                    to:   'asset.assetStatusId'
                }
            },
            through: 'asset'
        }
    };
    validations = {
        name: rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerAssetTypeId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const assetType = new AssetTypeRelation();

class AssetStatusRelation extends RelationHelpers {
    tableName = 'assetStatus';
    modelName = 'AssetStatus';
    correlate = 'assetStatuses';
    notify = false;
    idColumn = 'assetStatusId';
    providerIdColumn = 'providerAssetStatusId';
    links = null;
    sync = 1;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'AssetStatusServices';
    converter = 'assetStatus';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [ 'providerAssetStatusId' ];
    fields = [
        'assetStatusId',
        'description',
        'providerAssetStatusId',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        assetStatus_Asset: {
            target: 'asset',
            join:   {
                from: 'assetStatus.assetStatusId',
                to:   'asset.assetStatusId'
            }
        },
        assetStatus_Asset_Company_AssetStatus: {
            target: 'company',
            join:   {
                from:    'assetStatus.assetStatusId',
                to:      'company.companyId',
                through: {
                    from: 'asset.assetStatusId',
                    to:   'asset.companyId'
                }
            },
            through: 'asset'
        },
        assetStatus_Asset_User_AssetStatus: {
            target: 'user',
            join:   {
                from:    'assetStatus.assetStatusId',
                to:      'user.contactId',
                through: {
                    from: 'asset.assetStatusId',
                    to:   'asset.contactId'
                }
            },
            through: 'asset'
        },
        assetStatus_Asset_Site_AssetStatus: {
            target: 'site',
            join:   {
                from:    'assetStatus.assetStatusId',
                to:      'site.siteId',
                through: {
                    from: 'asset.assetStatusId',
                    to:   'asset.siteId'
                }
            },
            through: 'asset'
        },
        assetStatus_Asset_Manufacturer_AssetStatus: {
            target: 'manufacturer',
            join:   {
                from:    'assetStatus.assetStatusId',
                to:      'manufacturer.manufacturerId',
                through: {
                    from: 'asset.assetStatusId',
                    to:   'asset.manufacturerId'
                }
            },
            through: 'asset'
        },
        assetStatus_Asset_Sla_AssetStatus: {
            target: 'sla',
            join:   {
                from:    'assetStatus.assetStatusId',
                to:      'sla.slaId',
                through: {
                    from: 'asset.assetStatusId',
                    to:   'asset.slaId'
                }
            },
            through: 'asset'
        },
        assetStatus_Asset_AssetType_AssetStatus: {
            target: 'assetType',
            join:   {
                from:    'assetStatus.assetStatusId',
                to:      'assetType.assetTypeId',
                through: {
                    from: 'asset.assetStatusId',
                    to:   'asset.assetTypeId'
                }
            },
            through: 'asset'
        }
    };
    validations = {
        description: rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerAssetStatusId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const assetStatus = new AssetStatusRelation();

class AssetRelation extends RelationHelpers {
    tableName = 'asset';
    modelName = 'Asset';
    correlate = 'assets';
    notify = false;
    idColumn = 'assetId';
    providerIdColumn = 'providerAssetId';
    links = null;
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'AssetServices';
    converter = 'asset';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'companyId',
        'assetName',
        'providerAssetId'
    ];
    fields = [
        'assetId',
        'companyId',
        'assetTypeId',
        'assetStatusId',
        'siteId',
        'contactId',
        'manufacturerId',
        'slaId',
        'assetName',
        'details',
        'questions',
        'providerAssetId',
        'version',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [
        'details',
        'questions',
        'version'
    ];
    relations = {
        asset_Company: {
            target: 'company',
            join:   {
                from: 'asset.companyId',
                to:   'company.companyId'
            }
        },
        asset_User: {
            target: 'user',
            join:   {
                from: 'asset.contactId',
                to:   'user.contactId'
            }
        },
        asset_Site: {
            target: 'site',
            join:   {
                from: 'asset.siteId',
                to:   'site.siteId'
            }
        },
        asset_Manufacturer: {
            target: 'manufacturer',
            join:   {
                from: 'asset.manufacturerId',
                to:   'manufacturer.manufacturerId'
            }
        },
        asset_Sla: {
            target: 'sla',
            join:   {
                from: 'asset.slaId',
                to:   'sla.slaId'
            }
        },
        asset_AssetType: {
            target: 'assetType',
            join:   {
                from: 'asset.assetTypeId',
                to:   'assetType.assetTypeId'
            }
        },
        asset_AssetStatus: {
            target: 'assetStatus',
            join:   {
                from: 'asset.assetStatusId',
                to:   'assetStatus.assetStatusId'
            }
        },
        asset_AssetProviderSite: {
            target: 'assetProviderSite',
            join:   {
                from: 'asset.assetId',
                to:   'assetProviderSite.assetId'
            }
        },
        asset_JobAsset: {
            target: 'jobAsset',
            join:   {
                from: 'asset.assetId',
                to:   'jobAsset.assetId'
            }
        },
        asset_JobAsset_Job_Asset: {
            target: 'job',
            join:   {
                from:    'asset.assetId',
                to:      'job.jobId',
                through: {
                    from: 'jobAsset.assetId',
                    to:   'jobAsset.jobId'
                }
            },
            through: 'jobAsset'
        }
    };
    validations = {
        assetName: rxAny
    };
    copy = [
        {
            from: 'id',
            to:   'providerAssetId'
        }
    ];
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const asset = new AssetRelation();

class AssetProviderSiteRelation extends RelationHelpers {
    tableName = 'assetProviderSite';
    modelName = 'AssetProviderSite';
    correlate = null;
    notify = false;
    idColumn = 'assetProviderSiteId';
    providerIdColumn = null;
    links = {
        left:  'asset',
        right: 'providerSite'
    };
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'AssetProviderSiteServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [ 'assetId' ];
    fields = [
        'assetProviderSiteId',
        'assetId',
        'providerSiteId',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        assetProviderSite_Asset: {
            target: 'asset',
            join:   {
                from: 'assetProviderSite.assetId',
                to:   'asset.assetId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const assetProviderSite = new AssetProviderSiteRelation();

class JobAssetRelation extends RelationHelpers {
    tableName = 'jobAsset';
    modelName = 'JobAsset';
    correlate = 'assets';
    notify = false;
    idColumn = 'jobAssetId';
    providerIdColumn = null;
    links = {
        left:  'job',
        right: 'asset'
    };
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'JobAssetServices';
    converter = 'jobAsset';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'jobId',
        'assetId'
    ];
    fields = [
        'jobAssetId',
        'jobId',
        'assetId',
        'createdOn',
        'updatedOn',
        'version'
    ];
    jsonFields = [ 'version' ];
    relations = {
        jobAsset_Job: {
            target: 'job',
            join:   {
                from: 'jobAsset.jobId',
                to:   'job.jobId'
            }
        },
        jobAsset_Asset: {
            target: 'asset',
            join:   {
                from: 'jobAsset.assetId',
                to:   'asset.assetId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = [];
    conflictFields = [
        'jobId',
        'assetId'
    ];
}

export const jobAsset = new JobAssetRelation();

class TagRelation extends RelationHelpers {
    tableName = 'tag';
    modelName = 'Tag';
    correlate = null;
    notify = false;
    idColumn = 'tagId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'TagServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'tagType',
        'tagName'
    ];
    fields = [
        'tagId',
        'tagType',
        'tagName',
        'parentTagId',
        'userId',
        'active',
        'isPrivate',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        tag_User: {
            target: 'user',
            join:   {
                from: 'tag.userId',
                to:   'user.userId'
            }
        },
        tag_TagUser: {
            target: 'tagUser',
            join:   {
                from: 'tag.tagId',
                to:   'tagUser.tagId'
            }
        },
        tag_TagJob: {
            target: 'tagJob',
            join:   {
                from: 'tag.tagId',
                to:   'tagJob.tagId'
            }
        },
        tag_TagUser_User_Tag: {
            target: 'user',
            join:   {
                from:    'tag.tagId',
                to:      'user.userId',
                through: {
                    from: 'tagUser.tagId',
                    to:   'tagUser.userId'
                }
            },
            through: 'tagUser'
        },
        tag_TagJob_Job_Tag: {
            target: 'job',
            join:   {
                from:    'tag.tagId',
                to:      'job.jobId',
                through: {
                    from: 'tagJob.tagId',
                    to:   'tagJob.jobId'
                }
            },
            through: 'tagJob'
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const tag = new TagRelation();

class TagUserRelation extends RelationHelpers {
    tableName = 'tagUser';
    modelName = 'TagUser';
    correlate = null;
    notify = false;
    idColumn = 'tagUserId';
    providerIdColumn = null;
    links = {
        left:  'tag',
        right: 'user'
    };
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'TagUserServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'userId',
        'tagId'
    ];
    fields = [
        'tagUserId',
        'userId',
        'tagId',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        tagUser_User: {
            target: 'user',
            join:   {
                from: 'tagUser.userId',
                to:   'user.userId'
            }
        },
        tagUser_Tag: {
            target: 'tag',
            join:   {
                from: 'tagUser.tagId',
                to:   'tag.tagId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = null;
    conflictFields = [
        'userId',
        'tagId'
    ];
}

export const tagUser = new TagUserRelation();

class TagJobRelation extends RelationHelpers {
    tableName = 'tagJob';
    modelName = 'TagJob';
    correlate = null;
    notify = false;
    idColumn = 'tagJobId';
    providerIdColumn = null;
    links = {
        left:  'tag',
        right: 'job'
    };
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'TagJobServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'jobId',
        'tagId'
    ];
    fields = [
        'tagJobId',
        'jobId',
        'tagId',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        tagJob_Job: {
            target: 'job',
            join:   {
                from: 'tagJob.jobId',
                to:   'job.jobId'
            }
        },
        tagJob_Tag: {
            target: 'tag',
            join:   {
                from: 'tagJob.tagId',
                to:   'tag.tagId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = null;
    conflictFields = [
        'jobId',
        'tagId'
    ];
}

export const tagJob = new TagJobRelation();

class CompanyUserRelation extends RelationHelpers {
    tableName = 'companyUser';
    modelName = 'CompanyUser';
    correlate = null;
    notify = false;
    idColumn = 'companyUserId';
    providerIdColumn = null;
    links = {
        left:  'company',
        right: 'user'
    };
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'CompanyUserServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [ 'isDefaultContact' ];
    required = [
        'userId',
        'companyId'
    ];
    fields = [
        'companyUserId',
        'userId',
        'companyId',
        'isDefaultContact',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        companyUser_Company: {
            target: 'company',
            join:   {
                from: 'companyUser.companyId',
                to:   'company.companyId'
            }
        },
        companyUser_User: {
            target: 'user',
            join:   {
                from: 'companyUser.userId',
                to:   'user.userId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = [ 'isDefaultContact' ];
    conflictFields = [
        'userId',
        'companyId'
    ];
}

export const companyUser = new CompanyUserRelation();

class UserJobRelation extends RelationHelpers {
    tableName = 'userJob';
    modelName = 'UserJob';
    correlate = 'schedules';
    notify = false;
    idColumn = 'userJobId';
    providerIdColumn = 'providerUserJobId';
    links = {
        left:  'user',
        right: 'job'
    };
    sync = 3;
    syncable = true;
    crudable = true;
    dates = [];
    controllerName = 'UserJobServices';
    converter = 'resource';
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [ 'isJobOwner' ];
    required = [
        'userId',
        'jobId'
    ];
    fields = [
        'userJobId',
        'userId',
        'jobId',
        'providerUserJobId',
        'version',
        'isJobOwner',
        'role',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'version' ];
    relations = {
        userJob_User: {
            target: 'user',
            join:   {
                from: 'userJob.userId',
                to:   'user.userId'
            }
        },
        userJob_Job: {
            target: 'job',
            join:   {
                from: 'userJob.jobId',
                to:   'job.jobId'
            }
        }
    };
    validations = {
        userId: rxUUID,
        jobId:  rxUUID
    };
    copy = [
        {
            from: 'id',
            to:   'providerUserJobId'
        }
    ];
    parent = null;
    waitsFor = {
        job: 'jobId'
    };
    conflict = [
        'version',
        'providerUserJobId'
    ];
    conflictFields = [
        'userId',
        'jobId'
    ];
}

export const userJob = new UserJobRelation();

class UserAppointmentRelation extends RelationHelpers {
    tableName = 'userAppointment';
    modelName = 'UserAppointment';
    correlate = null;
    notify = false;
    idColumn = 'userAppointmentId';
    providerIdColumn = null;
    links = {
        left:  'user',
        right: 'appointment'
    };
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'UserAppointmentServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [ 'isPrimary' ];
    required = [
        'appointmentId',
        'userId'
    ];
    fields = [
        'userAppointmentId',
        'appointmentId',
        'userId',
        'isPrimary',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        userAppointment_User: {
            target: 'user',
            join:   {
                from: 'userAppointment.userId',
                to:   'user.userId'
            }
        },
        userAppointment_Appointment: {
            target: 'appointment',
            join:   {
                from: 'userAppointment.appointmentId',
                to:   'appointment.appointmentId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = null;
    conflictFields = [
        'userId',
        'appointmentId'
    ];
}

export const userAppointment = new UserAppointmentRelation();

class NotificationUnviewedRelation extends RelationHelpers {
    tableName = 'notificationUnviewed';
    modelName = 'NotificationUnviewed';
    correlate = null;
    notify = false;
    idColumn = 'notificationUnviewedId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'NotificationUnviewedServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'notificationId',
        'userId'
    ];
    fields = [
        'notificationUnviewedId',
        'notificationId',
        'userId',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        notificationUnviewed_User: {
            target: 'user',
            join:   {
                from: 'notificationUnviewed.userId',
                to:   'user.userId'
            }
        },
        notificationUnviewed_Notification: {
            target: 'notification',
            join:   {
                from: 'notificationUnviewed.notificationId',
                to:   'notification.notificationId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = null;
    conflictFields = [
        'userId',
        'notificationId'
    ];
}

export const notificationUnviewed = new NotificationUnviewedRelation();

class ProviderNameRelation extends RelationHelpers {
    tableName = 'providerName';
    modelName = 'ProviderName';
    correlate = null;
    notify = false;
    idColumn = 'providerNameId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'ProviderNameServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [];
    fields = [
        'providerNameId',
        'name',
        'description',
        'providerSource',
        'providerSourceId',
        'providerBelongsToId',
        'typeName',
        'data',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'data' ];
    relations = {
        providerName_Mapping: {
            target: 'mapping',
            join:   {
                from: 'providerName.providerNameId',
                to:   'mapping.providerNameId'
            }
        },
        providerName_Mapping_LicoriceName_ProviderName: {
            target: 'licoriceName',
            join:   {
                from:    'providerName.providerNameId',
                to:      'licoriceName.licoriceNameId',
                through: {
                    from: 'mapping.providerNameId',
                    to:   'mapping.licoriceNameId'
                }
            },
            through: 'mapping'
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = [
        'name',
        'description',
        'data',
        'typeName'
    ];
    conflictFields = [
        'providerSource',
        'providerSourceId',
        'providerBelongsToId'
    ];
}

export const providerName = new ProviderNameRelation();

class MappingRelation extends RelationHelpers {
    tableName = 'mapping';
    modelName = 'Mapping';
    correlate = null;
    notify = false;
    idColumn = 'mappingId';
    providerIdColumn = null;
    links = {
        left:  'licoriceName',
        right: 'providerName'
    };
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'MappingServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'licoriceNameId',
        'providerNameId'
    ];
    fields = [
        'mappingId',
        'licoriceNameId',
        'providerNameId',
        'deprecated',
        'preferred',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        mapping_LicoriceName: {
            target: 'licoriceName',
            join:   {
                from: 'mapping.licoriceNameId',
                to:   'licoriceName.licoriceNameId'
            }
        },
        mapping_ProviderName: {
            target: 'providerName',
            join:   {
                from: 'mapping.providerNameId',
                to:   'providerName.providerNameId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = [];
    conflictFields = [
        'licoriceNameId',
        'providerNameId'
    ];
}

export const mapping = new MappingRelation();

class HistoryRelation extends RelationHelpers {
    tableName = 'history';
    modelName = 'History';
    correlate = null;
    notify = false;
    idColumn = 'historyId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'HistoryServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'table',
        'action',
        'id'
    ];
    fields = [
        'historyId',
        'table',
        'action',
        'id',
        'parentId',
        'userId',
        'data',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [ 'data' ];
    relations = {
        history_User: {
            target: 'user',
            join:   {
                from: 'history.userId',
                to:   'user.userId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const history = new HistoryRelation();

class CompanySiteRelation extends RelationHelpers {
    tableName = 'companySite';
    modelName = 'CompanySite';
    correlate = null;
    notify = false;
    idColumn = 'companySiteId';
    providerIdColumn = null;
    links = {
        left:  'company',
        right: 'site'
    };
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'CompanySiteServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [ 'isPrimary' ];
    required = [
        'companyId',
        'siteId'
    ];
    fields = [
        'companySiteId',
        'companyId',
        'siteId',
        'isPrimary',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        companySite_Company: {
            target: 'company',
            join:   {
                from: 'companySite.companyId',
                to:   'company.companyId'
            }
        },
        companySite_Site: {
            target: 'site',
            join:   {
                from: 'companySite.siteId',
                to:   'site.siteId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = null;
    conflictFields = [
        'companyId',
        'siteId'
    ];
}

export const companySite = new CompanySiteRelation();

class UserSiteRelation extends RelationHelpers {
    tableName = 'userSite';
    modelName = 'UserSite';
    correlate = null;
    notify = false;
    idColumn = 'userSiteId';
    providerIdColumn = null;
    links = {
        left:  'user',
        right: 'site'
    };
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'UserSiteServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [ 'isPrimary' ];
    required = [
        'userId',
        'siteId'
    ];
    fields = [
        'userSiteId',
        'userId',
        'siteId',
        'isPrimary',
        'createdOn',
        'updatedOn'
    ];
    jsonFields = [];
    relations = {
        userSite_User: {
            target: 'user',
            join:   {
                from: 'userSite.userId',
                to:   'user.userId'
            }
        },
        userSite_Site: {
            target: 'site',
            join:   {
                from: 'userSite.siteId',
                to:   'site.siteId'
            }
        }
    };
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = null;
    conflictFields = [
        'userId',
        'siteId'
    ];
}

export const userSite = new UserSiteRelation();

class JournalRelation extends RelationHelpers {
    tableName = 'journal';
    modelName = 'Journal';
    correlate = null;
    notify = false;
    idColumn = 'journalId';
    providerIdColumn = null;
    links = null;
    sync = 0;
    syncable = false;
    crudable = true;
    dates = [];
    controllerName = 'JournalServices';
    converter = null;
    ignore = [
        'createdOn',
        'updatedOn'
    ];
    exclusive = [];
    required = [
        'type',
        'data'
    ];
    fields = [
        'journalId',
        'type',
        'data',
        'createdOn'
    ];
    jsonFields = [ 'data' ];
    relations = {};
    validations = null;
    copy = null;
    parent = null;
    waitsFor = null;
    conflict = undefined;
    conflictFields = undefined;
}

export const journal = new JournalRelation();

const databaseTables = {
    dbstate,
    company,
    provider,
    baseProvider,
    user,
    licoriceName,
    companyStatusNote,
    job,
    appointment,
    calendarEvent,
    schedulerEvent,
    token,
    notification,
    timeLog,
    checklist,
    email,
    note,
    noteAttachment,
    configuration,
    site,
    manufacturer,
    sla,
    assetType,
    assetStatus,
    asset,
    assetProviderSite,
    jobAsset,
    tag,
    tagUser,
    tagJob,
    companyUser,
    userJob,
    userAppointment,
    notificationUnviewed,
    providerName,
    mapping,
    history,
    companySite,
    userSite,
    journal
};


const relationLookups       = new Map();
const allRelations          = new Map();
const tableNameToFieldNames = new Map();
const fieldMappings         = new Map();

Object.entries( databaseTables )
    .forEach( ([ tableName, data ]) => {
        if ( !data.fields?.length || ( data.relations == null || isEmpty( data.relations ) ) ) return;

        relationLookups.set( tableName, Object.values( data.relations ) );
        relationLookups.set( ucf( tableName ), Object.values( data.relations ) );
    });

Object.values( databaseTables ).forEach( m => {
    tableNameToFieldNames.set( m.tableName, new Set( m.fields ?? []) );

    if ( !m.fields?.length ) return;

    if ( !isEmpty( m.relations ) )
    {
        Object.entries( m.relations )
            .forEach( ([ name, value ]) => {
                if ( allRelations.has( name ) )
                    console.error( `Duplicate name found in "${m.tableName}" for relation "${name}"` );

                allRelations.set( name, value );
            });
    }

    for ( const fieldName of m.fields )
    {
        if ( !fieldMappings.has( fieldName ) )
            fieldMappings.set( fieldName, new Set([ m.tableName ]) );
        else
            fieldMappings.get( fieldName ).add( m.tableName );
    }
});

export {
    relationLookups,
    allRelations,
    tableNameToFieldNames,
    databaseTables
};

// noinspection JSUnusedGlobalSymbols
export default databaseTables;
export { RelationHelpers }; // For test purposes only; do not use directly.
