/*********************************************************************************************************************
 * @file Jobcard top panel component
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 11-Jan-2020
 *********************************************************************************************************************/

import React, { PureComponent } from 'react';

import { StatusNames, integrationNames } from '@licoriceio/constants';
import { Tooltip } from "@mui/material";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { nameDataFormat } from '../../constants.js';
import { deleteJobThunk } from '../../redux/reducers/job.js';
import { selectJobHasNotes, getJobCompany, selectNameData } from '../../redux/selectors/index.js';
import { jobCardTopPanel, deleteButton, deleteIcon, jobId, closeButton, closeIcon,
    cardTypeToggle, toggleLabel, toggleSelected, toggleNormal, toggleDisabled, jobCompany, jobSeparator, jobLabel, jobTitle
} from '../../scss/JobCardTopPanel.module.scss';
import { __, _$ } from '../../utils/i18n.jsx';
import { 
    UX_JOBCARD_DELETE_BUTTON, UX_JOBCARD_CLOSE_BUTTON, 
    UX_JOBCARD_TYPE_SELECTOR_JOB, UX_JOBCARD_TYPE_SELECTOR_INTERNAL, UX_JOBCARD_TYPE_SELECTOR_PERSONAL 
} from '../../ux-constants.js';
import { addCalendarEventThunk, deleteCalendarEventRequest } from '../busycard/reducer.js';
import { closeEitherCard } from '../calendar/shared.js';
import { addJobThunk } from '../calendar/thunks.js';
import ProviderJobLink from '../common/atomic/ProviderJobLink.jsx';
import { LicoFab, LicoIcon, WarningConfirmDialog, NumberDropdown } from '../common/index.js';

import { localJobCardChange, localJobCardSave } from './reducer.js';

const ToggleSelector = ({ labels, selectedIndex, onChange, disabled }) => {
    const otherClass = disabled ? toggleDisabled : toggleNormal;
    const onClick = i => () => !disabled && i !== selectedIndex && onChange( i );
    const className = i => `${toggleLabel} ${i === selectedIndex ? toggleSelected : otherClass}`;

    return <div className={cardTypeToggle} data-outer="holder">
        {
            labels.map( ( label, i ) => <div key={`${label}-${i}`} className={className( i )} onClick={onClick( i )}>{label}</div> )
        }
    </div>;
};

ToggleSelector.propTypes = {
    labels:        PropTypes.arrayOf( PropTypes.any ).isRequired,
    selectedIndex: PropTypes.number,
    onChange:      PropTypes.func.isRequired,
    disabled:      PropTypes.bool
};

const JobLabel = ({ title, companyName }) => {

    return title || companyName 
        ? <>
            {companyName ? <span className={jobLabel}>
                <Tooltip title={companyName}>
                    <span className={jobCompany}>{companyName}</span>
                </Tooltip>
            </span>
                : ""}
            {companyName && title ? <span className={jobSeparator}> | </span> : ""}
            {title ? <span className={jobLabel}><span className={jobTitle}>{title}</span></span> : ""}
        </>
        : <span data-ux={UX_JOBCARD_TYPE_SELECTOR_JOB}>{__( "Job" )}</span>;
};
JobLabel.propTypes = {
    title:          PropTypes.string,
    companyName:      PropTypes.string
};

class JobCardTopPanel extends PureComponent
{
    constructor( props ) {
        super( props );
        this.state = { confirmDialogOpen: false };
    }

    closeDialog = doDelete => {
        const { job, calendarEvent, deleteJobThunk, deleteCalendarEventRequest } = this.props;
        if ( doDelete ) {
            if ( job )
                deleteJobThunk( job );
            else
                deleteCalendarEventRequest( calendarEvent );
        }
        this.setState({ confirmDialogOpen: false });
    };

    render() {
        const {
            job,
            calendarEvent,
            jobHasNotes,
            addCalendarEventThunk,
            jobAddedFromPegboard,
            addJobThunk,
            closeEitherCard,
            companyName,
            jobStatusOptions,
            defaultStatus,
            completed
        } = this.props;

        const {
            confirmDialogOpen
        } = this.state;

        const savedToDB = ( job && !job.newItemFlag ) || ( calendarEvent && !calendarEvent.newItemFlag );

        // can't switch between job and busy card for existing items, once any changes have been made.
        // Can't switch at all if adding on PB (must stay with Job, the default)
        const typeLocked = savedToDB || jobAddedFromPegboard;
        const itemName = job ? __( "Job" ) : calendarEvent.isPrivate ? __( "Personal Card" ) : __( "Internal Card" );

        // index for card type toggle
        const cardIndex = job ? 0 : calendarEvent.isPrivate ? 2 : 1;

        const slicePackage = {
            slice:              'job',
            id:                 job?.jobId,
            localChangeThunk:   localJobCardChange,
            localSaveThunk:     localJobCardSave
        };

        return <div className={jobCardTopPanel}>

            <WarningConfirmDialog
                title={_$( "Delete this {itemName}?", { itemName })}
                message={_$( "Are you sure you want to delete this {itemName}?", { itemName })}
                isOpen={confirmDialogOpen}
                onCancel={() => this.closeDialog( false )}
                onContinue={() => this.closeDialog( true )}
            />

            <LicoFab
                data-ux={UX_JOBCARD_DELETE_BUTTON}
                disabled={jobHasNotes || completed}
                onClick={event => {
                    if ( event.shiftKey )
                        this.closeDialog( true );
                    else
                        this.setState({ confirmDialogOpen: true });
                }}
                style={{ visibility: savedToDB ? 'visible' : 'hidden' }}
                disabledTooltip={completed
                    ? __( "This job has been resolved and cannot be deleted" )
                    : __( "This job has saved worklog entries and cannot be deleted" )}
                className={deleteButton}
            >
                <LicoIcon icon="delete" className={deleteIcon} />
            </LicoFab>

            <ToggleSelector
                labels={[ 
                    <JobLabel key={0} companyName={companyName} title={job?.title} />, 
                    <span key={1} data-ux={UX_JOBCARD_TYPE_SELECTOR_INTERNAL}>{__( "Internal" )}</span>, 
                    <span key={2} data-ux={UX_JOBCARD_TYPE_SELECTOR_PERSONAL}>{__( "Personal" )}</span>
                ]}
                selectedIndex={cardIndex}
                onChange={index => {
                    if ( index === 0 )
                        addJobThunk();
                    else
                        addCalendarEventThunk( index === 2 );
                }}
                disabled={typeLocked}
            />

            <ProviderJobLink providerJobId={job?.providerJobId} className={jobId} />

            {job && <NumberDropdown
                value={{ statusId: job.statusId || defaultStatus }}
                options={jobStatusOptions}
                disabled={completed || !savedToDB}
                inputClassName='rounded jcField'
                minimumwidth={220}
                slicePackage={slicePackage}
                displayOnlyLabels={[ StatusNames.UNMAPPED_STATUS, StatusNames.RESOLVED_JOB ]}
            />}

            <LicoFab
                data-ux={UX_JOBCARD_CLOSE_BUTTON}
                onClick={closeEitherCard} 
                className={closeButton}>
                <LicoIcon icon="close" className={closeIcon} />
            </LicoFab>
        </div>;
    }

    static propTypes = {
        job:                        PropTypes.any,
        calendarEvent:              PropTypes.any,
        jobHasNotes:                PropTypes.bool.isRequired,
        addCalendarEventThunk:      PropTypes.func.isRequired,
        deleteJobThunk:             PropTypes.func.isRequired,
        deleteCalendarEventRequest: PropTypes.func.isRequired,
        addJobThunk:                PropTypes.func.isRequired,
        jobAddedFromPegboard:       PropTypes.bool.isRequired,
        closeEitherCard:            PropTypes.func.isRequired,
        companyName:                PropTypes.string,
        jobStatusOptions:           PropTypes.array,
        defaultStatus:              PropTypes.string,
        completed:                  PropTypes.bool.isRequired
    };
}

const mapStateToProps = ( state, props ) => {

    const { defaultStatus, newJobDetails } = state.jobcard;
    
    return {
        jobHasNotes:            selectJobHasNotes( state ),
        jobAddedFromPegboard:   newJobDetails?.jobAddedFromPegboard || false,
        companyName:            getJobCompany( state, props.job?.jobId )?.companyName,
        jobStatusOptions:       selectNameData( state, integrationNames.JOB_STATUS, nameDataFormat.OPTIONS ),
        defaultStatus
    };
};

const mapDispatchToProps = {
    addCalendarEventThunk,
    deleteJobThunk,
    deleteCalendarEventRequest,
    addJobThunk,
    closeEitherCard
};

export default connect( mapStateToProps, mapDispatchToProps )( JobCardTopPanel );
export { JobCardTopPanel };
