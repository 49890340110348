import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { deleteTagRequest } from '../../redux/genericThunks.js';
import { tagChip, deleteIcon } from '../../scss/TagChip.module.scss';

import { LicoIcon } from './index.js';

const TagChip = props => {
    const {
        tagName,
        tagType,
        tagOwnerId,
        deleteTagRequest,
        canDelete
    } = props;

    return <span className={tagChip}>#{tagName}
        { canDelete && <>
            &nbsp;
            <span onClick={() => deleteTagRequest({ data: { tagName, tagType }, tagOwnerId })}>
                <LicoIcon icon="close" className={deleteIcon} />
            </span></>}
            
    </span>;
};

TagChip.propTypes = {
    tagName:                    PropTypes.string.isRequired,
    tagType:                    PropTypes.string.isRequired,
    tagOwnerId:                 PropTypes.string.isRequired,
    deleteTagRequest:           PropTypes.func.isRequired,
    canDelete:                  PropTypes.bool
};
const mapDispatchToProps = {
    deleteTagRequest
};

export default connect( null, mapDispatchToProps )( TagChip );
export { TagChip };
