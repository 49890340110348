const buildEnv = {
    HOSTNAME:         '16804d13-5afd-4e50-9e1a-4a2b7d19b257-shp7c',
    SERVER_PORT:      undefined,
    LICORICE_VERSION: '2.5.0-rc.2',
    BUILD_VERSION:    '50.a9c9937',
    NODE_ENV:         'development',
    DEBUG_NAMES:      '',
    BUILD_TIME_LOCAL: '2024-11-22T03:56:24+00:00',
    BUILD_TIME_UTC:   '2024-11-22T03:56:24.326Z'
};

export { buildEnv };
