/********************************************************************************************************************
 * @file Actions for the search panel store
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const setJobs = createAction( 'SEARCH.JOBS/SET' );
export const setPatchJob = createAction( 'SEARCH.PATCH/SET' );
export const setRemoveJob = createAction( 'SEARCH.REMOVE/SET' );
export const setJobOffset = createAction( 'SEARCH.JOB_OFFSET/SET' );
export const setSearchPanelHeight = createAction( 'SEARCH.SEARCH_PANEL_HEIGHT/SET' );
export const setCalculateJobPage = createAction( 'SEARCH.CALCULATE_JOB_PAGE/SET' );
export const setRemoveFromSearchPanel = createAction( 'SEARCH.REMOVE.SEARCH_PANEL.TAB/SET' );
export const setAddToSearchPanel = createAction( 'SEARCH.ADD.SEARCH_PANEL.TAB/SET' );
export const setFilterList = createAction( 'SEARCH.FILTER_LIST/SET' );
export const setActiveTabId = createAction( 'SEARCH.ACTIVE_TAB/SET' );
export const setSearchPanel = createAction( 'SEARCH.SEARCH_PANEL/SET' );
export const setJobCardCreatedTab = createAction( 'SEARCH.JOB_CARD_CREATED_TAB/SET' );
export const setSearchFilterString = createAction( 'SEARCH.FILTER_STRING/SET' );
export const setAddFilterItem = createAction( 'SEARCH.ADD_FILTER_ITEM/SET' );
export const setRemoveFilterItem = createAction( 'SEARCH.REMOVE_FILTER_ITEM/SET' );
