import { GET, POST, uri } from "../../constants.js";
import { setJobNotes, setJobCardUsers, setJobCardEngineers, setJobCardChecklist, setJobCardAssets } from "../../redux/actions/index.js";
import { genericRequest } from "../../redux/reducerUtil.js";
import { abstractedCreateAuthRequest } from "../../services/util/baseRequests.js";

const getJobNotes = jobId =>
    genericRequest(
        {}, abstractedCreateAuthRequest( GET, uri.JOB_NOTES, a => a, false ), setJobNotes, [ jobId ], {
            pageSize:       1000,
            ignoreActive:   true,
            related:        [ 'note->user', 'note(timeLogId)->?timeLog' ],
            fields:         [ 'note.*', 'user.name', 'user.preferences', 'user.role', 'timeLog.billable', 'timeLog.billingTypeId' ]
        }
    );


const getJobCardUsers = jobId => genericRequest({}, abstractedCreateAuthRequest( GET, uri.JOB_USERS, a => a ), setJobCardUsers, [ jobId ]);

const getJobCardEngineers = jobId => genericRequest({}, abstractedCreateAuthRequest( GET, uri.JOB_ENGINEERS, a => a ), setJobCardEngineers, [ jobId ]);

const getJobCardChecklist = jobId => genericRequest({}, abstractedCreateAuthRequest( GET, uri.JOB_CHECKLIST, a => a ), setJobCardChecklist, [ jobId ]);

// the GET and DELETE requests don't go via the usual path since they're done within the EditorImage component
const addFileService = abstractedCreateAuthRequest( POST, uri.FILE );

const getJobCardAssets = jobId => genericRequest({}, abstractedCreateAuthRequest( GET, uri.JOB_ASSETS ), [ setJobCardAssets ], [ jobId ]);

export {
    getJobNotes,
    getJobCardUsers,
    getJobCardEngineers,
    getJobCardChecklist,
    addFileService,
    getJobCardAssets
};
