/********************************************************************************************************************
 * @file Index file for the redux actions.
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { createAction } from 'redux-actions';

export const startLoading = createAction( 'LOADING/GET' );
export const stopLoading = createAction( 'LOADING/SET' );
export const setAuth = createAction( 'AUTH.AUTH/SET' );

export const setTypeNames = createAction( 'NAMES.TYPE_NAMES/SET' );

export const USER_LOGOUT = 'USER_LOGOUT';

export * from './appointment.js';
export * from './calendarEvent.js';
export * from './calendar.js';
export * from './cache.js';
export * from './company.js';
export * from './client.js';
export * from './engineer.js';
export * from './error.js';
export * from './forgotPassword.js';
export * from './integration.js';
export * from './invitation.js';
export * from './jobCard.js';
export * from './job.js';
export * from './journal.js';
export * from './login.js';
export * from './meta.js';
export * from './notification.js';
export * from './organisation.js';
export * from './pegboard.js';
export * from './registration.js';
export * from './scheduling.js';
export * from './search.js';
export * from './typeAhead.js';
export * from './user.js';
export * from './userDetails.js';
export * from './userProfile.js';
export * from './team.js';

