/********************************************************************************************************************
 * @file Index file for the redux selectors.
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 17-Dec-2021
 *********************************************************************************************************************/

import { integrationTypes } from '@licoriceio/constants';

export const selectSystemReady = state => ( state.meta.integrationType === integrationTypes.STANDALONE ) || !!state.integration.provider?.integrated;

export * from './cache.js';
export * from './calendar.js';
export * from './calendarEvent.js';
export * from './error.js';
export * from './isAuth.js';
export * from './job.js';
export * from './jobcard.js';
export * from './loading.js';
export * from './meta.js';
export * from './names.js';
export * from './notifications.js';
export * from './storeSelectors.js';
export * from './user.js';
