/********************************************************************************************************************
 * @file busycard.js - busy card selectors
 * @author Ian Macdonald <imacdonald@licorice.io>
 * @since 1.0.0
 * @date 21/12/2021
 *********************************************************************************************************************/

import { selectNameDataReady } from "./names.js";

export const selectCurrentCalendarEventId = state => selectNameDataReady( state ) ? state.calendarEvent.currentCalendarEventId : undefined;

export const selectNewBusyCardDetails = state => state.calendarEvent.busyCardDetails;

export const selectUserCalendarEvents = ( state, userId ) => {
    const { calendarEvent: { userMap } } = state;
    const calendar = userMap[ userId ];

    const { _calendarEvents, earlyEventsInView, lateEventsInView } = calendar;

    return {
        calendarEvents:         selectNameDataReady( state ) ? _calendarEvents : [],
        earlyEventsInView,
        lateEventsInView
    };
};

/**
 * Return the calendarEvent with the specified id
 * @param {BaseState} state - global state
 * @param {string} calendarEventId - calendarEvent Id
 * @returns {FeCalendarEvent}
 */
export const getCalendarEventFromState = ( state, calendarEventId ) => {

    // we have multiple calendarEvent maps, one per engineer calendar, so we really
    // need a user id as well. However, that would make the job/busy card switching much
    // trickier, so just check all open calendars for this id.

    if ( !calendarEventId )
        return null;

    // find the user id who owns this event.
    const users = Object.values( state.calendarEvent.userMap );
    const index = users.findIndex( user => user.calendarEventMap[ calendarEventId ]);
    return index >= 0 ? users[ index ].calendarEventMap[ calendarEventId ] : null;
};

