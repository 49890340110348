import { integrationNames } from '@licoriceio/constants';

// define the "home" slice for each type of name data; some types appear in more than one slice (eg jobStatus)
// because reducers can only see their own slice but this is the slice that serves the data to components, thunks, etc,
// which start with a top level state object.
const nameTypeSlice = {
    [ integrationNames.PERSONAL ]:      'calendarEvent',
    [ integrationNames.INTERNAL ]:      'calendarEvent',
    [ integrationNames.JOB_STATUS ]:    'jobcard',
    [ integrationNames.BILLING_TYPE ]:  'jobcard'
};

export const selectNameData = ( state, nameType, dataName ) => {
    const slice = nameTypeSlice?.[ nameType ];
    if ( !slice )
        throw new Error( `No slice for name type ${nameType}` );
    return state?.[ slice ]?.[ nameType ]?.[ dataName ];
};

export const selectNameDataReady = state => {
    const nameTypeSlices = Object.entries( nameTypeSlice );
    const readyCount = nameTypeSlices.filter( ([ nameType, slice ]) => state[ slice ][ nameType ]?.nameToId ).length;
    return readyCount === nameTypeSlices.length;
};
