import React, { useCallback, useState } from 'react';

import { NotificationTypes } from '@licoriceio/constants';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, connect } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { snackbarKey } from '../../constants.js';
import { setMeta } from '../../redux/actions/index.js';
import { 
    selectIsAuthAndHasAcceptedTerms, selectSearchPanelShown, selectMonitorLog, selectNotificationPanelShown, selectNotificationCount, selectUser 
} from '../../redux/selectors/index.js';
import "./calendar.css";
import { header, headerFill, logoContainer, headerButton, activeHeaderButton, squareButtonLabel, wideTooltip } from '../../scss/OurStandardAuthLayout.module.scss';
import { getAvatarFromUserOrCompany }         from "../../utils/common-types.js";
import { __ } from '../../utils/i18n.jsx';
import { UX_HOME_LINK, UX_UNSCHEDULED_BUTTON, UX_NOTIFICATION_ALERT_BUTTON } from '../../ux-constants.js';
import BannerLogo from '../common/images/BannerLogo.jsx';
import { StandardAuthLayout, LicoLink, LicoIcon } from "../common/index.js";
import LicoSnackbar from '../common/LicoSnackbar.jsx';
import { LOGIN, HOME } from '../navigation/routes.js';
import UserMenu from '../navigation/UserMenu.jsx';

import NotificationAlert from "./NotificationAlert.jsx";


/**
 * @typedef {object} SquareButtonProps
 * @property {string} label
 * @property {string} icon
 * @property {function} onClick
 * @property {boolean} active
 */

const SquareButton = ({ label, tooltip, icon, onClick, active, dataName }) => (
    <Tooltip title={tooltip} >
        <div className={`${headerButton} ${active ? activeHeaderButton : ""}`} onClick={onClick} data-ux={dataName}>
            <LicoIcon icon={icon} size="1x"/>
            <div className={squareButtonLabel}>{label}</div>
        </div>
    </Tooltip>
);

SquareButton.propTypes = {
    label:             PropTypes.string.isRequired,
    tooltip:           PropTypes.string,
    icon:              PropTypes.string,
    onClick:           PropTypes.func,
    active:            PropTypes.bool,
    dataName:          PropTypes.string
};

const MonitorLine = ({ item }) => {

    // default line
    let line = `${item.time} ${item.type}`;

    const data = item.type === NotificationTypes.DATA_RECORDS_UPDATE
        ? JSON.parse( item.text ).data
        : null;
    if ( data ) 
    {
        if ( data.type === 'userJob' )
        {
            // userJob changes don't follow the added/updated/removed pattern
            if ( data.modifications?.engineers )
            {
                const [ action, records ] = data.modifications?.engineers.add?.length 
                    ? [ 'A', data.modifications?.engineers.add ]
                    : [ 'D', data.modifications?.engineers.delete ];
                line = `${item.time} DRU ${data.type} Eng ${action} ${records[ 0 ]}`;
            }
            else 
                // I'm not sure we're producing these (I couldn't generate one in any obvious way).
                // User updates from CW come via job updates.
                line = `${item.time} DRU ${data.type} User ?`;

        }
        else
        {
            const [ action, records ] = data.updated?.length 
                ? [ 'U', data.updated ]
                : data.added?.length 
                    ? [ 'A', data.added ] 
                    : data.removed?.length 
                        ? [ 'D', data.removed ]
                        : [ null, null ];
            if ( records?.length )
            {
                const label = records[ 0 ].title ?? '';
                line = `${item.time} DRU ${data.type} ${action} ${label}`;
            }
            else 
                line = `${item.time} DRU ${data.type} unknown action`;
        }
    }
    
    return <div><Tooltip 
        title={<pre>{item.text}</pre>}
        placement="left"
        classes={{ popper: wideTooltip }}
    >
        <div style={{ fontSize: 12 }}>{line}</div>
    </Tooltip></div>;
};
MonitorLine.propTypes = {
    item:               PropTypes.object.isRequired
};

const OurStandardAuthLayout = ({ isAuthenticated, user, searchPanelShown, children, monitorLog, notificationCount, notificationPanelShown }) => {
    const avatar = user && getAvatarFromUserOrCompany( user );
    const dispatch = useDispatch();
    const location = useLocation();
    const [ showMonitor, setShowMonitor ] = useState( false );

    const toggleTicketConverter = useCallback( e => {
        
        if ( e.shiftKey ) {
            setShowMonitor( flag => !flag );
            return;
        }

        // added notificationPanelShown: false to ensure that it is in its initial state when the ticket panel is opened/ toggled
        dispatch( setMeta({ searchPanelShown: !searchPanelShown, notificationPanelShown: false }) );
    }, [ dispatch, searchPanelShown ]);


    const showNotificationPanel = useCallback( e => {

        if ( e.shiftKey ) {
            setShowMonitor( flag => !flag );
            return;
        }

        // added searchPanelShown to ensure that the searchPanelShown should be updated to  its state.
        dispatch( setMeta({ notificationPanelShown: true, searchPanelShown: false }) );
    }, [ dispatch ]);

    return <>

        {!isAuthenticated && <Redirect to={LOGIN} />}

        <StandardAuthLayout
            headerChildren={
                <div className={header}>
                    <div className={logoContainer}>
                        <LicoLink to={HOME} dataName={UX_HOME_LINK}>
                            <BannerLogo />
                        </LicoLink>
                    </div>
                    <div className={headerFill} />
                    {
                        /settings/.test( location.pathname ) ||
                        <SquareButton 
                            onClick={toggleTicketConverter} 
                            label={__( "Search" )}
                            icon="magnifyingGlass"
                            active={searchPanelShown || false}
                            dataName={UX_UNSCHEDULED_BUTTON}
                        />
                    }
                    <UserMenu gravatarEmail={user?.preferences.gravatarEmail} avatar={avatar} />
                    {/settings/.test( location.pathname ) ||
                    <NotificationAlert
                        onClick={showNotificationPanel}
                        count={notificationCount}
                        dataName={UX_NOTIFICATION_ALERT_BUTTON}
                        showNotificationPanel={notificationPanelShown}
                    />
                    }
                </div>
            }
        >
            {/* 
            Yes, at some point this will need to be extracted to a single component which will list all the possible snacks
            so that it can be reused (although this covers everywhere as of now) and simply to keep this component clean.
            But this is not that point.
             */}
            <LicoSnackbar
                metaKey={ snackbarKey.LOGGED_OUT_INACTIVE }
                label={__( "You've been logged out due to being made inactive." )} 
            />
            <LicoSnackbar
                metaKey={ snackbarKey.LOGGED_OUT_GOT_ADMIN }
                label={__( "You've been logged out due to gaining Admin rights." )} 
            />
            <LicoSnackbar
                metaKey={ snackbarKey.LOGGED_OUT_LOST_ADMIN }
                label={__( "You've been logged out due to losing Admin rights." )} 
            />
            <LicoSnackbar
                metaKey={ snackbarKey.JOB_APPT_REQUEST_SENT }
                label={__( "A job appointment request has been sent." )} 
            />
            {children}
            {showMonitor && <div 
                style={{
                    position:           'absolute', 
                    right:              10, 
                    top:                80, 
                    backgroundColor:    '#00ff0022',
                    color:              '#789df9',
                    width:              260, 
                    height:             900, 
                    zIndex:             50,
                    overflowY:          'scroll'
                }}>
                {monitorLog.map( ( item, i ) => <MonitorLine key={i} item={item } /> )}
            </div>}
        </StandardAuthLayout></>;
};

OurStandardAuthLayout.propTypes = {
    isAuthenticated:        PropTypes.bool,
    user:                   PropTypes.object,
    searchPanelShown:       PropTypes.bool,
    children:               PropTypes.node,
    monitorLog:             PropTypes.array,
    notificationCount:      PropTypes.number,
    notificationPanelShown: PropTypes.bool
};

const mapStateToProps = state => {
    return {
        isAuthenticated:        selectIsAuthAndHasAcceptedTerms( state ),
        user:                   selectUser( state ),
        searchPanelShown:       selectSearchPanelShown( state ),
        monitorLog:             selectMonitorLog( state ),
        notificationCount:      selectNotificationCount( state ),
        notificationPanelShown: selectNotificationPanelShown( state )
    };
};

export default connect( mapStateToProps )( OurStandardAuthLayout );
export { OurStandardAuthLayout };
