import React from 'react';

import { Tooltip } from "@mui/material";
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';


import enlarge from "../../images/enlarge.svg";
import shrink from "../../images/shrink.svg";
import { licoTabs, tab, panel, panelMax, resizeIcon, addNewTab, dynamicPanel, dynamicTabStyle, dynamicLicoTabs, dynamicLicoTabsMainContainer, jobCreatedTab } from '../../scss/LicoTabs.module.scss';
import { __ } from "../../utils/i18n.jsx";

import { LicoIcon } from "./images/LicoIcon.jsx";

const TabPanel = ({ children, containerClassName, value, index, persistentContent, contentVisible, setContentVisible, showResizeIcon, dynamicTab, ...other }) => {
    const tooltipTitle = contentVisible ? __( "Resize ( ALT +F)" ) : __( "Enlarge ( ALT +F)" );
    const imageSrc = contentVisible ? shrink : enlarge;
    const imageAlt = contentVisible ? "shrink notes" : "expand notes";
    const handleContentView = () => {
        setContentVisible( !contentVisible );
    };

    // inactive panels (where value !== index) are always hidden at div level;
    // whether the content for these panels renders or not is controlled by
    // persistentContent
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            className={containerClassName}
            style={ persistentContent || value === index ? {} : { display: "none" }}
            {...other}
        >
            { showResizeIcon && <div className={resizeIcon}  >
                <Tooltip title={tooltipTitle}>
                    <div onClick={handleContentView}>
                        <img src={imageSrc} alt={imageAlt} />
                    </div>
                </Tooltip>
            </div>}
            {( value === index || persistentContent ) && <div className={contentVisible ? panelMax : dynamicTab ? dynamicPanel : panel }>{children}</div>}
        </div>
    );
};

TabPanel.propTypes = {
    children:           PropTypes.node,
    index:              PropTypes.any.isRequired,
    value:              PropTypes.any,
    persistentContent:  PropTypes.bool.isRequired,
    contentVisible:      PropTypes.bool,
    setContentVisible:   PropTypes.func,
    showResizeIcon:      PropTypes.bool,
    dynamicTab:          PropTypes.bool,
    containerClassName:  PropTypes.string
};

const LicoTabs = ({ content,
    persistentContent = false,
    onKeyDown,
    contentVisible,
    setContentVisible,
    tabIndex,
    setTabIndex,
    showResizeIcon,
    className,
    showCloseIcon,
    onClose,
    dynamicTab = false,
    tabPanelClassName,
    onDynamicTabClicked }) => {

    return (
        <div className={`${dynamicTab ? `${dynamicLicoTabs} ${className}` : `${licoTabs} ${className}`}`} >
            <Tabs
                className={`${dynamicTab ? `${dynamicLicoTabs} ${dynamicLicoTabsMainContainer} ${className}` : `${licoTabs} ${className}`}` }
                value={tabIndex}
                variant="scrollable"
                onChange={( e, index ) => {
                    return setTabIndex( index );
                }}
            >
                {
                    dynamicTab && <Tab
                        data-value={`tab-0`}
                        className={addNewTab}
                        onClick={onDynamicTabClicked}
                        icon={<LicoIcon icon={"add"} />}
                    />
                }
                {content.map( ( t, index ) => {
                    let className = dynamicTab ? dynamicTabStyle : tab;
                    if ( t.jobCardCreatedTab ) 
                        className = `${className} ${jobCreatedTab}`;
                    
                    return <Tab
                        data-value={`tab-${index}`}
                        key={`${t.label}-${index}`}

                        label={<div style={{ overflow: "hidden", whiteSpace: "nowrap", maxWidth: 100, textOverflow: "ellipsis" }}>{t.label}</div>}
                        className={className}
                        onKeyDown={onKeyDown}
                        onAuxClick={() => showCloseIcon && onClose( index ) }
                        icon={showCloseIcon
                            ? <LicoIcon icon={"close"} style={{ marginLeft: "10px" }} onClick={( event ) => {
                                event.stopPropagation();
                                onClose( index );
                            }}
                            /> : null
                        }


                    />;
                }) }

            </Tabs>
            {content.map( ( tab, i ) => {
                return <TabPanel
                    key={dynamicTab ? i + 1 : i}
                    value={tabIndex || 0}
                    index={dynamicTab ? i + 1 : i}
                    persistentContent={persistentContent}
                    contentVisible={contentVisible}
                    showResizeIcon={showResizeIcon}
                    dynamicTab={dynamicTab}
                    containerClassName={tabPanelClassName}
                    setContentVisible={setContentVisible}>{tab.content}</TabPanel>;
            }) }
        </div>
    );
};

LicoTabs.propTypes = {
    content:             PropTypes.array.isRequired,
    persistentContent:   PropTypes.bool,
    onKeyDown:           PropTypes.func,
    contentVisible:      PropTypes.bool,
    setContentVisible:   PropTypes.func,
    tabIndex:            PropTypes.number,
    setTabIndex:         PropTypes.func,
    showResizeIcon:      PropTypes.bool,
    className:           PropTypes.string,
    showCloseIcon:       PropTypes.bool,
    onClose:             PropTypes.func,
    dynamicTab:          PropTypes.bool,
    onDynamicTabClicked: PropTypes.func,
    tabPanelClassName:   PropTypes.string

};

export default LicoTabs;
export { LicoTabs };
