import { DELETE, JobTagTypes } from '@licoriceio/constants';
import { asArray } from '@licoriceio/utils';

import { POST, uri } from "../constants";
import { abstractedCreateAuthRequest  } from "../services/util/baseRequests";

import { setJobTag, deleteJobTag } from './actions/index.js';
import { genericRequest } from "./reducerUtil.js";

const addTagRequest = payload => genericRequest( payload.data, abstractedCreateAuthRequest( POST, uri.JOB_TAGS ), setTagsThunk, [ payload.tagOwnerId ]);
const deleteTagRequest = payload => genericRequest( payload.data, abstractedCreateAuthRequest( DELETE, uri.JOB_TAGS ), deleteJobTag, [ payload.tagOwnerId ]);

const setTagsThunk = payload => dispatch => {

    const tags = asArray( payload );

    // the list of tags may include tags with functionally significant type, eg job type, job subtype etc.
    // find those in the list, action them, and strip them from the list (we will presumably have generic
    // job tags at some stage and we don't want these showing up there too).
    JobTagTypes.forEach( type => {
        const index = tags.findIndex( tag => tag.tagType === type );
        if ( index >= 0 )
        {
            dispatch( setJobTag( tags[ index ]) );
            tags.splice( index, 1 );
        }
    });
};



export { addTagRequest, deleteTagRequest, setTagsThunk };
