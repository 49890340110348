import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { nop } from '@licoriceio/utils';
import { MenuItem, TextField, Tooltip } from '@mui/material';
import { makeStyles, styled } from '@mui/styles';
import PropTypes from 'prop-types';

import { dispatchBlurThunk, dispatchChangeAction, installKeySaveActions } from '../../../../redux/reducerUtil.js';
import { H2, P } from '../typography/index.js';

const useStyles = makeStyles({
    root:   {},
    select: {
        margin: 0
    },
    inlineFull: {
        marginTop: 3
    },
    inlineLabel: {
        display:       "flex",
        flexDirection: "row",
        alignItems:    "center"
    },
    inlineMargin: {
        marginBottom:  15
    },
    label: {
        marginBottom: 9

    },
    subLabel: {
        marginBottom: 9,
        fontSize:     12
    }
});

const RoundedTextField = styled( TextField, 
    {
        shouldForwardProp: ( prop ) => prop !== 'rounded' && prop != 'minimumwidth'
    })( ({ rounded, minimumwidth = 16 }) => ({
    ...( rounded 
        ? {
            '& .MuiOutlinedInput-root': {
                borderRadius:   "24px",
                marginTop:      "0px"
            },
            '& .MuiSelect-outlined': {
                borderRadius:   "24px"
            },
            '& .MuiSelect-select': {
                borderRadius:   "24px",
                minWidth:       `${minimumwidth}px`      
            }
        } 
        : {
            '& .MuiSelect-select': {
                minWidth:       `${minimumwidth}px`      
            }
        })
}) );

// if input list is null or empty, control should be disabled
export const LicoSelect = props => {
    const {
        label,
        name,
        subLabel,
        options,
        disabled,
        initialValue,
        labelClassName,
        inputClassName,
        externalValue,
        inlineLabel,
        inlineFull,
        noMargins,
        slicePackage,
        autoFocus,
        tooltip,
        noLabel,
        minimumwidth,
        displayOnlyLabels,
        rounded,
        addEmptyOption
    } = props;
    let { onSelect = nop, onKeyDown } = props;
    const classes = useStyles( props );

    if ( slicePackage ) {
        onSelect = e => {
            dispatchChangeAction( slicePackage, { updates: { [ name ]: e.target.value } });
            dispatchBlurThunk( slicePackage, { field: name });
        };

        // don't need to save on a key since we save on any selection
        if ( slicePackage.keySaveAction )
            onKeyDown = installKeySaveActions( slicePackage, undefined, props.onKeyDown );
    }

    const realDisabled = disabled || options.length === 0;

    const realOptions = useMemo( () => {
        const emptyOption = { id: "", label: "" };

        return options && options.length > 0 
            ? displayOnlyLabels
            
                // displayOnlyLabels is used when we have an option required for display
                // of existing values but the option shouldn't be selectable as a new value.
                ? [ ...options.filter( option => option.id === externalValue || !displayOnlyLabels.includes( option.label ) ) ]
                : addEmptyOption
                    ? [ emptyOption, ...options ]
                    : options
            : [ emptyOption ];
    }, [ options, externalValue, displayOnlyLabels, addEmptyOption ]);

    const [ selectedId, setSelectedId ] = useState( externalValue || ( initialValue ? initialValue.id : realOptions[ 0 ].id ) );

    useEffect( () => {

        // externalValue is provided when control of the field value is required at a higher level.
        // We use this in a failsafe fashion (see value prop for TextField below) in case the value and list
        // are out of sync.
        if ( externalValue !== null && externalValue !== undefined )
            setSelectedId( externalValue );
    }, [ externalValue, setSelectedId ]);

    const handleChange = useCallback( event => {
        event.stopPropagation();
        // send to parent handler
        onSelect( event, realOptions.find( v => v.id === event.target.value ) );

        // change local state if we're using it, otherwise a new externalValue prop will come down and and change this
        if ( externalValue === undefined || externalValue === null )
            setSelectedId( event.target.value );
    }, [ onSelect, externalValue, realOptions, setSelectedId ]);

    const labels = noLabel ? null : subLabel
        ? <React.Fragment>
            <H2 className={classes.label} gutterBottom={false}>
                {label}
            </H2>
            <P className={classes.subLabel} gutterBottom={false}>{subLabel}</P>
        </React.Fragment>
        : <H2 className={labelClassName || classes.label} gutterBottom={false}>
            {label}
        </H2>;

    const control =
        <RoundedTextField
            name={name}
            select
            fullWidth={Boolean( labelClassName === undefined ||  inlineFull )}
            variant="outlined"
            value={selectedId !== null && realOptions.map( o => o.id ).includes( selectedId ) ? selectedId : realOptions[ 0 ].id}
            disabled={realDisabled}
            onChange={handleChange}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
            inputProps={{
                className:       `${inputClassName}`,
                background:      "white",
                "data-lpignore": true
            }}
            className={labelClassName ? inlineFull ? classes.inlineFull : "" : classes.select}
            rounded={/rounded/.test( inputClassName ) || rounded ? "true" : ""}
            minimumwidth={minimumwidth}
        >
            {realOptions.filter( item => item.id ).map( ( v, i ) => <MenuItem value={v.id} key={i}>{v.label}</MenuItem> )}
        </RoundedTextField>;

    const tippedControl = tooltip ? <Tooltip title={tooltip} placement='right-start'>{control}</Tooltip> : control;

    return noLabel
        ? tippedControl
        : <label className={`${inlineLabel ? classes.inlineLabel : classes.root} ${noMargins ? '' : classes.inlineMargin}`}>
            {labels}
            {tippedControl}
        </label>;
};

LicoSelect.propTypes = {
    label:              PropTypes.string,
    name:               PropTypes.string,
    subLabel:           PropTypes.string,
    options:            PropTypes.array,
    onSelect:           PropTypes.func,
    disabled:           PropTypes.bool,
    initialValue:       PropTypes.object,
    inputClassName:     PropTypes.string,
    labelClassName:     PropTypes.string,
    externalValue:      PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
    inlineLabel:        PropTypes.bool,
    inlineFull:         PropTypes.bool,
    noMargins:          PropTypes.bool,
    slicePackage:       PropTypes.object,
    autoFocus:          PropTypes.bool,
    onKeyDown:          PropTypes.func,
    tooltip:            PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]),
    noLabel:            PropTypes.bool,
    minimumwidth:       PropTypes.number,
    displayOnlyLabels:  PropTypes.array,
    rounded:            PropTypes.bool,
    addEmptyOption:     PropTypes.bool
};
