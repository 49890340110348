// noinspection JSUnusedGlobalSymbols

/** ******************************************************************************************************************
 * This file is automatically generated so don't make any changes here or they'll get wiped out. To generate an
 * updated version of this file, run `yarn build`. This file is mostly generated by several goblins.
 * 
 * @author Julian Jensen <jjensen@licorice.io>
 *********************************************************************************************************************/

// @troll start database-names-loud in Database as template "const %NAME = '%name';"
const APPOINTMENT = 'appointment';
const ASSET = 'asset';
const ASSET_PROVIDER_SITE = 'assetProviderSite';
const ASSET_STATUS = 'assetStatus';
const ASSET_TYPE = 'assetType';
const BASE_PROVIDER = 'baseProvider';
const CALENDAR_EVENT = 'calendarEvent';
const CHECKLIST = 'checklist';
const COMPANY = 'company';
const COMPANY_SITE = 'companySite';
const COMPANY_STATUS_NOTE = 'companyStatusNote';
const COMPANY_USER = 'companyUser';
const CONFIGURATION = 'configuration';
const DBSTATE = 'dbstate';
const EMAIL = 'email';
const HISTORY = 'history';
const JOB = 'job';
const JOB_ASSET = 'jobAsset';
const JOURNAL = 'journal';
const LICORICE_NAME = 'licoriceName';
const MANUFACTURER = 'manufacturer';
const MAPPING = 'mapping';
const NOTE = 'note';
const NOTE_ATTACHMENT = 'noteAttachment';
const NOTIFICATION = 'notification';
const NOTIFICATION_UNVIEWED = 'notificationUnviewed';
const PROVIDER = 'provider';
const PROVIDER_NAME = 'providerName';
const SCHEDULER_EVENT = 'schedulerEvent';
const SITE = 'site';
const SLA = 'sla';
const TAG = 'tag';
const TAG_JOB = 'tagJob';
const TAG_USER = 'tagUser';
const TIME_LOG = 'timeLog';
const TOKEN = 'token';
const USER = 'user';
const USER_APPOINTMENT = 'userAppointment';
const USER_JOB = 'userJob';
const USER_SITE = 'userSite';
// @troll end

const modelNames = [
    // @troll start database-names-list in Database as template "%NAME," [align, comma]
    APPOINTMENT,
    ASSET,
    ASSET_PROVIDER_SITE,
    ASSET_STATUS,
    ASSET_TYPE,
    BASE_PROVIDER,
    CALENDAR_EVENT,
    CHECKLIST,
    COMPANY,
    COMPANY_SITE,
    COMPANY_STATUS_NOTE,
    COMPANY_USER,
    CONFIGURATION,
    DBSTATE,
    EMAIL,
    HISTORY,
    JOB,
    JOB_ASSET,
    JOURNAL,
    LICORICE_NAME,
    MANUFACTURER,
    MAPPING,
    NOTE,
    NOTE_ATTACHMENT,
    NOTIFICATION,
    NOTIFICATION_UNVIEWED,
    PROVIDER,
    PROVIDER_NAME,
    SCHEDULER_EVENT,
    SITE,
    SLA,
    TAG,
    TAG_JOB,
    TAG_USER,
    TIME_LOG,
    TOKEN,
    USER,
    USER_APPOINTMENT,
    USER_JOB,
    USER_SITE
    // @troll end
];

// @troll start database-types-js
const UserRole = {
    engineer: 'engineer',
    user:     'user'
};

const Priority = {
    critical: 'critical',
    high:     'high',
    normal:   'normal'
};

const AppointmentState = {
    init:        'init',
    active:      'active',
    done:        'done',
    cancelled:   'cancelled',
    deleted:     'deleted',
    rescheduled: 'rescheduled'
};

const JobType = {
    job: 'job',
    st:  'st'
};

const RecurringType = {
    daily:   'daily',
    weekly:  'weekly',
    monthly: 'monthly',
    yearly:  'yearly',
    custom:  'custom'
};

const TokenPurpose = {
    invitation: 'invitation',
    pwdreset:   'pwdreset',
    schedule:   'schedule',
    reserved1:  'reserved1',
    reserved2:  'reserved2'
};

const TokenStatus = {
    init:     'init',
    sent:     'sent',
    used:     'used',
    expired:  'expired',
    obsolete: 'obsolete',
    disabled: 'disabled'
};

const NotificationStatus = {
    active:    'active',
    expired:   'expired',
    dismissed: 'dismissed',
    inactive:  'inactive',
    deleted:   'deleted'
};

const TagType = {
    team:          'team',
    project:       'project',
    category:      'category',
    communication: 'communication',
    event:         'event',
    invalid:       'invalid',
    reserved1:     'reserved1',
    reserved2:     'reserved2',
    jobType:       'jobType',
    jobSubType:    'jobSubType',
    jobItem:       'jobItem'
};

const NoteType = {
    message:     'message',
    worklog:     'worklog',
    description: 'description',
    ignore:      'ignore'
};
// @troll end

const integrationTypes = {
    INITIAL:        0,
    INTEGRATED:     1,
    STANDALONE:     2
};

const JournalTypes = {
    INTEGRATION:    1,
    SYNC:           2,
    MAPPING:        3,
    UNKNOWN:        4
};

const JobTagTypes = [ TagType.jobType, TagType.jobSubType, TagType.jobItem ];

export {
    // @troll start database-types-exports-js [align, comma]
    UserRole,
    Priority,
    AppointmentState,
    JobType,
    RecurringType,
    TokenPurpose,
    TokenStatus,
    NotificationStatus,
    TagType,
    NoteType,
    // @troll end

    // @troll start database-names-loud-export in Database as template "%NAME," [align, comma]
    APPOINTMENT,
    ASSET,
    ASSET_PROVIDER_SITE,
    ASSET_STATUS,
    ASSET_TYPE,
    BASE_PROVIDER,
    CALENDAR_EVENT,
    CHECKLIST,
    COMPANY,
    COMPANY_SITE,
    COMPANY_STATUS_NOTE,
    COMPANY_USER,
    CONFIGURATION,
    DBSTATE,
    EMAIL,
    HISTORY,
    JOB,
    JOB_ASSET,
    JOURNAL,
    LICORICE_NAME,
    MANUFACTURER,
    MAPPING,
    NOTE,
    NOTE_ATTACHMENT,
    NOTIFICATION,
    NOTIFICATION_UNVIEWED,
    PROVIDER,
    PROVIDER_NAME,
    SCHEDULER_EVENT,
    SITE,
    SLA,
    TAG,
    TAG_JOB,
    TAG_USER,
    TIME_LOG,
    TOKEN,
    USER,
    USER_APPOINTMENT,
    USER_JOB,
    USER_SITE,
    // @troll end

    modelNames,
    integrationTypes,
    JournalTypes,
    JobTagTypes
};
